import React from 'react'
import { Spin } from 'antd'

export default function Loading() {
  return (
    <div style={{display: 'block', position: 'fixed', top: '50%', right: '50%'}}>
      <Spin size="large" />
    </div>
  )
}
