import moment from  'moment';
import { Link, NavLink } from "react-router-dom";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";

export const eventsColumn = (deletePost) => [
  {
    title: 'Community',
    dataIndex: 'description',
    key: '1',
    render: (description) => (
        <p>
            {description?.community_name || '-'}
        </p>
    )
  },
  {
    title: 'Reported Item',
    dataIndex: 'description',
    key: '1',
    render: (description) => (
        <p>
            {description?.comment_id ? 'Comment' : description.comment_index ? 'Comment' : 'Post'}
        </p>
    )
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: '2',
    render: (type) => (
        <p>
            {type || '-'}
        </p>
    )
  },
  {
    title: 'Reported Content',
    dataIndex: 'description',
    key: '3',
    render: (description) => (
        <p>
            {description?.text || '-'}
        </p>
    )
  },
  {
    title: 'Created Date',
    dataIndex: 'description',
    key: '4',
    render: (description) => (
        <p>
            {description?.created_at ? moment(description?.created_at).format('ll') : '-'}
        </p>
    )
  },
  {
    title: 'Delete',
    dataIndex: 'description',
    key: '5',
    align: 'center',
    render: (description, data) => {
        return data.deleted ?
        <CheckOutlined />
        : (
            <DeleteOutlined 
                onClick={() => {
                    description.report_id = data._id
                    deletePost(description)
                }}
                disabled={(description?.comment_index || (description?.comment_index === 0))}
            />
        )
    }
  },
];
