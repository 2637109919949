import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PublicRoute({children, ...rest }) {
  return (
    <Route 
      {...rest} 
      render={() => (
        children
  )} />
  )
}
