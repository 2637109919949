import { Tag, Select } from 'antd';
import moment from  'moment';
import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

export const allUsersColumn = (searchByEmail, deleteUser, handleChangeProgramType) => {
  return (
    [
      {
        title: 'Name',
        // width: 150,
        dataIndex: ['firstName', 'lastName'],
        key: 'name',
        render: (name, user) => {
          return (<p>{user.firstName} {user.lastName}</p>)
        }
      },
      {
        title: searchByEmail,
        width: 300,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Coaching',
        width: 80,
        key: 'coaching',
        dataIndex: 'coaching',
        render: coaching => (
          <>
            { (coaching && coaching.active) ?
                <Tag color={'green'}>
                  {coaching?.plan || 'Active'}
                </Tag>
                :
                <Tag color={'volcano'}>
                  Inactive
                </Tag>
            }
          </>
        ),
      },
      {
        title: "State",
        dataIndex: "patientState",
        key: "patientState",
        width: 80,
        render: (name, user) => {
          let { addressComponents, zipCodeData } = user
          let patientStateAbbr = ''
          if (addressComponents && addressComponents.length > 0) {
            addressComponents.forEach((addressComponent) => {
              let types = addressComponent.types || []
              if (types.includes('administrative_area_level_1'))
                patientStateAbbr = addressComponent.short_name
            })
          } else if (zipCodeData?.state) {
            patientStateAbbr = zipCodeData?.state
          }
          return (<p>{patientStateAbbr}</p>)
        }
      },
      {
        title: 'Last Signed In',
        dataIndex: 'loginTimeStamp',
        key: 'lastSignedIn',
        width: 130,
        render: loginTimeStamp => {
          let latestLogin = loginTimeStamp?.timestamps ? loginTimeStamp.timestamps.slice(-1)[0] : null
          return (
            latestLogin &&
            <p>{moment().diff(latestLogin, 'days') ? moment().diff(latestLogin, 'days') + ' days ago' : 'Today'}</p>
          )
        } 
      },
      {
        title: 'Member Since',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 130,
        render: created_at => {
          return (
            created_at &&
            <p>{moment(created_at).format('ll')}</p>
          )
        } 
      },
      {
        title: 'Phone Number',
        width: 130,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: phoneNumber => {
          return (
            phoneNumber &&
            <p>{phoneNumber}</p>
          )
        } 
      },
      {
        title: () => { return (<p>Total Logins</p>) },
        dataIndex: 'loginTimeStamp',
        key: 'loginTimeStamp',
        width: 60,
        render: (login, user) => {
          // to get only the login timestamps of current month applying filter and finding the length of array
          let referenceDate = moment().subtract(29, 'days').format('MM-DD-YYYY')
          let uniqueDateArray = login && login.timestamps && login.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
          let monthLogin = uniqueDateArray && uniqueDateArray.filter((data, index) => 
            moment(data).isSameOrAfter(referenceDate) 
            && moment(data).isSameOrAfter(moment(user.created_at).format('MM-DD-YYYY')) 
            && uniqueDateArray.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
          )
          // console.log('Login Monthly::: ',monthLogin)
          return (
            monthLogin &&
            <p>{monthLogin.length}</p>
          )
        }
      },
      {
        title: 'Engagement Rate (% of days in last 30 days)',
        dataIndex: 'loginTimeStamp',
        key: 'loginTimeStamp',
        width: 100,
        render: (login, user) => {
          let rate
          let referenceDate = moment().subtract(29, 'days').format('MM-DD-YYYY')
          let uniqueDateArray = login && login.timestamps && login.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
          let monthLogin = uniqueDateArray && uniqueDateArray.filter((data, index) => 
            moment(data).isSameOrAfter(referenceDate) 
            && moment(data).isSameOrAfter(moment(user.created_at).format('MM-DD-YYYY')) 
            && uniqueDateArray.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
          )

          let dateDiff = moment().startOf('day').diff(moment(user.created_at).startOf('day'), 'days') + 1
          // if the created_at is less than 30 divide by diff
          if(dateDiff < 30)
            rate = monthLogin && monthLogin.length/dateDiff * 100
          else
            rate = monthLogin && monthLogin.length/30 * 100

          return (
            login && login.timestamps &&
            <p>{rate.toFixed(0)}%</p>
          )
        }
      },
      {
        title: 'Program Type',
        dataIndex: 'coaching',
        key: 'enrollment',
        width: 150,
        render: (coaching, user) => {
          let progOptionList = [
            {key: 'dac', value: 'Dac-Patient'},
            {key: 'rapilot', value: 'Ra-Patient'},
            {key: 'ccfm', value: 'CCFM'},
          ]
          let userDeepLink = null
          if(user.dac)
            userDeepLink = 'Dac-Patient'
          if(user.rapilot)
            userDeepLink = 'Ra-Patient'
          if(user.ccfm)
            userDeepLink = 'CCFM'
          return (
            <div style={{flex: 1, flexDirection: 'row'}}>
              <Select
                // mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder="Select progm"
                value={userDeepLink}
                onChange={(value) => handleChangeProgramType(user.firebase_id, value)}
              >
                {
                  progOptionList.map((prog => (
                    <Option value={prog.key}>{prog.value}</Option>
                  )))
                }
              </Select>
            </div>
          )
        }
      },
      {
        title: 'Sign-up',
        dataIndex: 'platform',
        key: 'platform',
        width: 110,
        align: 'center',
        render: (platform, user) => (
          <>
            { user.longCovid && <p>Long-Covid</p> }
            { user?.marketingTitle && <p>{user?.marketingTitle}</p>}
            <p>{platform}</p>
          </>
        )
      },
      {
        title: 'Delete',
        dataIndex: 'firebase_id',
        key: 'delete',
        width: 60,
        align: 'center',
        render: (firebase_id) => (
          <DeleteOutlined 
            onClick={() => {
              deleteUser(firebase_id)
            }}
          />
        )
      },
    ]
  )
} 

export const paidUsersColumn = (searchByEmail, deleteUser, handleChangeProgramType) => {
  return (
    [
      {
        title: 'Name',
        // width: 150,
        dataIndex: ['firstName', 'lastName'],
        key: 'name',
        render: (name, user) => {
          return (<p>{user.firstName} {user.lastName}</p>)
        }
      },
      {
        title: searchByEmail,
        width: 300,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Coaching',
        width: 80,
        key: 'coaching',
        dataIndex: 'coaching',
        render: coaching => (
          <>
            { (coaching && coaching.active) ?
                <Tag color={'green'}>
                  {coaching?.plan || 'Active'}
                </Tag>
                :
                <Tag color={'volcano'}>
                  Inactive
                </Tag>
            }
          </>
        ),
      },
      {
        title: 'Coaching Start Date',
        dataIndex: 'coaching',
        key: 'coaching',
        render: coaching => (<p>{coaching ? moment(coaching.start).format('Do MMMM YYYY') : null}</p>)
      },
      {
        title: "State",
        dataIndex: "patientState",
        key: "patientState",
        width: 80,
        render: (patientState, user) => {
          let { addressComponents, zipCodeData } = user
          let patientStateAbbr = ''
          if (addressComponents && addressComponents.length > 0) {
            addressComponents.forEach((addressComponent) => {
              let types = addressComponent.types || []
              if (types.includes('administrative_area_level_1'))
                patientStateAbbr = addressComponent.short_name
            })
          } else if (zipCodeData?.state) {
            patientStateAbbr = zipCodeData?.state
          }
          return (<p>{patientStateAbbr}</p>)
        }
      },
      {
        title: 'Last Signed In',
        dataIndex: 'loginTimeStamp',
        key: 'lastSignedIn',
        width: 130,
        sorter: (a, b) => moment(a?.loginTimeStamp?.timestamps.slice(-1)[0] || null).unix() - moment(b?.loginTimeStamp?.timestamps.slice(-1)[0] || null).unix() ,
        render: loginTimeStamp => {
          let latestLogin = loginTimeStamp?.timestamps ? loginTimeStamp.timestamps.slice(-1)[0] : null
          return (
            latestLogin &&
            <p>{moment().diff(latestLogin, 'days') ? moment().diff(latestLogin, 'days') + ' days ago' : 'Today'}</p>
          )
        } ,
      },
      {
        title: 'Member Since',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 130,
        sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
        render: created_at => {
          return (
            created_at &&
            <p>{moment(created_at).format('ll')}</p>
          )
        } 
      },
      {
        title: 'Phone Number',
        width: 130,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: phoneNumber => {
          return (
            phoneNumber &&
            <p>{phoneNumber}</p>
          )
        } 
      },
      {
        title: () => { return (<p>Total Logins</p>) },
        dataIndex: 'loginTimeStamp',
        key: 'loginTimeStamp',
        width: 60,
        // sorter: (a, b) => {
        //   let referenceDate_a = moment().subtract(29, 'days').format('MM-DD-YYYY')
        //   let uniqueDateArray_a = a.loginTimeStamp && a.loginTimeStamp.timestamps && a.loginTimeStamp.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
        //   let monthLogin_a = uniqueDateArray_a && uniqueDateArray_a.filter((data, index) => 
        //     moment(data).isSameOrAfter(referenceDate_a) 
        //     && moment(data).isSameOrAfter(moment(a.created_at).format('MM-DD-YYYY')) 
        //     && uniqueDateArray_a.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
        //   )
        //   let referenceDate_b = moment().subtract(29, 'days').format('MM-DD-YYYY')
        //   let uniqueDateArray_b = b.loginTimeStamp && b.loginTimeStamp.timestamps && b.loginTimeStamp.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
        //   let monthLogin_b = uniqueDateArray_b && uniqueDateArray_b.filter((data, index) => 
        //     moment(data).isSameOrAfter(referenceDate_b) 
        //     && moment(data).isSameOrAfter(moment(b.created_at).format('MM-DD-YYYY')) 
        //     && uniqueDateArray_b.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
        //   )
        // return monthLogin_a?.length - monthLogin_b?.length
        // },
        render: (login, user) => {
          // to get only the login timestamps of current month applying filter and finding the length of array
          let referenceDate = moment().subtract(29, 'days').format('MM-DD-YYYY')
          let uniqueDateArray = login && login.timestamps && login.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
          let monthLogin = uniqueDateArray && uniqueDateArray.filter((data, index) => 
            moment(data).isSameOrAfter(referenceDate) 
            && moment(data).isSameOrAfter(moment(user.created_at).format('MM-DD-YYYY')) 
            && uniqueDateArray.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
          )
          // console.log('Login Monthly::: ',monthLogin)
          return (
            monthLogin &&
            <p>{monthLogin.length}</p>
          )
        }
      },
      {
        title: 'Engagement Rate (% of days in last 30 days)',
        dataIndex: 'loginTimeStamp',
        key: 'loginTimeStamp',
        width: 100,
        // sorter: (a, b) => {
        //   let rate_a
        //   let referenceDate_a = moment().subtract(29, 'days').format('MM-DD-YYYY')
        //   let uniqueDateArray_a = a.loginTimeStamp && a.loginTimeStamp.timestamps && a.loginTimeStamp.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
        //   let monthLogin_a = uniqueDateArray_a && uniqueDateArray_a.filter((data, index) => 
        //     moment(data).isSameOrAfter(referenceDate_a) 
        //     && moment(data).isSameOrAfter(moment(a.created_at).format('MM-DD-YYYY')) 
        //     && uniqueDateArray_a.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
        //   )
        //   let dateDiff_a = moment().startOf('day').diff(moment(a.created_at).startOf('day'), 'days') + 1
        //   // if the created_at is less than 30 divide by diff
        //   if(dateDiff_a < 30)
        //     rate_a = monthLogin_a && monthLogin_a?.length/dateDiff_a * 100
        //   else
        //     rate_a = monthLogin_a && monthLogin_a?.length/30 * 100
        //   let rate_b
        //   let referenceDate_b = moment().subtract(29, 'days').format('MM-DD-YYYY')
        //   let uniqueDateArray_b = b.loginTimeStamp && b.loginTimeStamp.timestamps && b.loginTimeStamp.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
        //   let monthLogin_b = uniqueDateArray_b && uniqueDateArray_b.filter((data, index) => 
        //     moment(data).isSameOrAfter(referenceDate_b) 
        //     && moment(data).isSameOrAfter(moment(b.created_at).format('MM-DD-YYYY')) 
        //     && uniqueDateArray_b.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
        //   )
        //   let dateDiff_b = moment().startOf('day').diff(moment(b.created_at).startOf('day'), 'days') + 1
        //   // if the created_at is less than 30 divide by diff
        //   if(dateDiff_b < 30)
        //     rate_b = monthLogin_b && monthLogin_b?.length/dateDiff_b * 100
        //   else
        //     rate_b = monthLogin_b && monthLogin_b?.length/30 * 100

        // return rate_a?.toFixed(0) - rate_b?.toFixed(0)
        // },
        render: (login, user) => {
          let rate
          let referenceDate = moment().subtract(29, 'days').format('MM-DD-YYYY')
          let uniqueDateArray = login && login.timestamps && login.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
          let monthLogin = uniqueDateArray && uniqueDateArray.filter((data, index) => 
            moment(data).isSameOrAfter(referenceDate) 
            && moment(data).isSameOrAfter(moment(user.created_at).format('MM-DD-YYYY')) 
            && uniqueDateArray.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
          )

          let dateDiff = moment().startOf('day').diff(moment(user.created_at).startOf('day'), 'days') + 1
          // if the created_at is less than 30 divide by diff
          if(dateDiff < 30)
            rate = monthLogin && monthLogin.length/dateDiff * 100
          else
            rate = monthLogin && monthLogin.length/30 * 100
            
          return (
            login && login.timestamps &&
            <p>{rate.toFixed(0)}%</p>
          )
        }
      },
      {
        title: 'Program Type',
        dataIndex: 'coaching',
        key: 'enrollment',
        width: 150,
        render: (coaching, user) => {
          let progOptionList = [
            {key: 'dac', value: 'Dac-Patient'},
            {key: 'rapilot', value: 'Ra-Patient'},
            {key: 'ccfm', value: 'CCFM'},
          ]
          let userDeepLink = null
          if(user.dac)
            userDeepLink = 'Dac-Patient'
          if(user.rapilot)
            userDeepLink = 'Ra-Patient'
          if(user.ccfm)
            userDeepLink = 'CCFM'
          return (
            <div style={{flex: 1, flexDirection: 'row'}}>
              <Select
                // mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder="Select progm"
                value={userDeepLink}
                onChange={(value) => handleChangeProgramType(user.firebase_id, value, user?.coaching)}
              >
                {
                  progOptionList.map((prog => (
                    <Option value={prog.key}>{prog.value}</Option>
                  )))
                }
              </Select>
            </div>
          )
        }
      },
      {
        title: 'Sign-up',
        dataIndex: 'platform',
        key: 'platform',
        width: 110,
        align: 'center',
        render: (platform, user) => (
          <>
            { user.longCovid && <p>Long-Covid</p> }
            { user?.marketingTitle && <p>{user?.marketingTitle}</p>}
            <p>{platform}</p>
          </>
        )
      },
      {
        title: 'Delete',
        dataIndex: 'firebase_id',
        key: 'delete',
        width: 60,
        align: 'center',
        render: (firebase_id) => (
          <DeleteOutlined 
            onClick={() => {
              deleteUser(firebase_id)
            }}
          />
        )
      },
    ]
  )
}

export const analyticsColumn = (monthlyData) => {
  function gcd(a, b) {
    if (b > a) {
      let temp = a;
      a = b;
      b = temp;
    }
    while (b != 0) {
      let m = a % b;
      a = b;
      b = m;
    }
    return a;
  }

  /* ratio is to get the gcd and divide each component by the gcd, then return a string with the typical colon-separated value */
  function ratio(x, y) {
    if (!x || !y) return "0:0";
    let c = gcd(x, y);
    return "" + x / c + ":" + y / c;
  }

  return [
    {
      title: "Timeline",
      // width: 150,
      dataIndex: ["created_at"],
      key: "timeline",
      render: (created_at) => (
        <p style={{ fontWeight: "bold" }}>{created_at}</p>
      ),
    },
    {
      title: "Total Users",
      // width: 150,
      dataIndex: ["total_users"],
      key: "total_users",
      render: (name, data, index) => {
        let { monthData } = data;
        return (
          <p>
            {monthData[0].total_no_of_users
              ? monthData[0].total_no_of_users
              : monthlyData &&
                monthlyData.reduce(
                  (previous, current, i) =>
                    index > i
                      ? // console.log(i, index);
                        previous - current.monthData[0].monthly_no_of_new_users
                      : previous,
                  monthlyData[0].monthData[0].total_no_of_users
                )}
          </p>
        );
      },
    },
    {
      title: "Paid Users",
      children: [
        {
          title: "New Paid - Membership",
          // width: 150,
          dataIndex: ["membership"],
          key: "membership",
          render: (name, data) => {
            let { monthData } = data;
            return <p>{monthData[0].membership_no}</p>;
          },
        },
        {
          title: "New Paid - All Access",
          // width: 150,
          dataIndex: ["premium"],
          key: "premium",
          render: (name, data) => {
            let { monthData } = data;
            return <p>{monthData[0].premium_no}</p>;
          },
        },
        {
          title: "Membership : All Access",
          // width: 150,
          dataIndex: ["membership_premium_ratio"],
          key: "membership_premium_ratio",
          render: (name, data) => {
            let { monthData } = data;
            return (
              <p>
                {ratio(
                  monthData[0].membership_no || 0,
                  monthData[0].premium_no || 0
                )}
              </p>
            );
          },
        },
        {
          title: "Total Paid",
          // width: 150,
          dataIndex: ["total_paid"],
          key: "total_paid",
          render: (name, data) => {
            let { monthData } = data;
            return <p>{monthData[0].no_of_subscribers}</p>;
          },
        },
      ],
    },
    {
      title: "Basic Users",
      children: [
        {
          title: "New Unpaid",
          // width: 150,
          dataIndex: ["unpaid"],
          key: "unpaid",
          render: (name, data) => {
            let { monthData } = data;
            return <p>{monthData[0].monthly_no_of_new_users}</p>;
          },
        },

        {
          title: "Total Unpaid",
          // width: 150,
          dataIndex: ["total_unpaid"],
          key: "total_unpaid",
          render: (name, data, index) => {
            let { monthData } = data;
            return (
              <p>
                {monthData[0].total_no_of_users
                  ? monthData[0].total_no_of_users -
                    monthData[0].no_of_subscribers
                  : monthlyData &&
                    monthlyData.reduce(
                      (previous, current, i) =>
                        index > i
                          ? // console.log(i, index);
                            previous -
                            current.monthData[0].monthly_no_of_new_users
                          : previous,
                      monthlyData[0].monthData[0].total_no_of_users -
                        monthlyData[0].monthData[0].no_of_subscribers
                    )}
              </p>
            );
          },
        },
      ],
    },

    {
      title: "Appointments",
      children: [
        {
          title: "Paid User Visits",
          // width: 150,
          dataIndex: ["paid_users_visits"],
          key: "paid_users_visits",
          render: (name, data) => {
            let { monthData } = data;
            return (
              <p>{monthData[0].appointment_data?.paidBookedAppointments}</p>
            );
          },
        },

        {
          title: "Basic User Visits",
          // width: 150,
          dataIndex: ["basic_users_visits"],
          key: "basic_users_visits",
          render: (name, data) => {
            let { monthData } = data;
            return (
              <p>{monthData[0].appointment_data?.basicBookedAppointments}</p>
            );
          },
        },
      ],
    },
  ];
};
