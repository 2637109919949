import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Table,
  Select,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Checkbox,
  notification,
} from "antd";
import moment from "moment";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { coachUsersColumn } from "../../components/Coaching/coachColumns";
import {
  getAllUserCoach,
  getUserCoachAppointment,
  getAllUserMessages,
  getAllUserLoginTime,
  updateUserProgram,
  getEducationUpdates,
} from "../../api/users";
import Loading from "../../components/Loading";
import { DownOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function CoachingCenter() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userCoach, setUserCoach] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [education, setEducation] = useState([]);
  // const [coachColumns, _setCoachColumns] = useState(coachUsersColumn || [])

  // // to get the latest state values
  // const coachColumnsRef = useRef(coachColumns);
  // const setCoachColumns = data => {
  //   coachColumnsRef.current = data;
  //   _setCoachColumns(data);
  // };

  const searchByEmail = (
    <Input
      placeholder="Search Email"
      value={searchEmail}
      onChange={(e) => {
        const email = e.target.value;
        setSearchEmail(email);
      }}
    />
  );

  const filterByNameEmail = (data) => {
    const filteredData = data.filter((user) => {
      let patientName = user.firstName + ' ' + user.lastName
      if(user?.email?.toLowerCase()?.includes(searchEmail.toLowerCase()))
        return user
      else if(patientName?.toLowerCase()?.includes(searchEmail.toLowerCase()))
        return user
    });
    return filteredData;
  };

  const handlePrgmChange = async (value, sugData) => {
    console.log("progm change: ", value, sugData);
    if (sugData && sugData.sg_user_id) {
      const { data: prgmChange, error: prgmChangeError } =
        await updateUserProgram({
          sg_user_id: sugData.sg_user_id,
          program_id: value,
          token: token,
        });
      console.log("RESP of Change of program: ", prgmChange);
      prgmChange && prgmChange.success && updateSuggesticData();
    }
  };

  const addCoach = (doctorID, patientID) => {
    console.log("add doc: ", doctorID, patientID);
    let config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "providers/patients",
      headers: {
        x_firebase_id: currentUser.uid,
        Authorization: "JWT " + token,
      },
      data: {
        doctor_id: doctorID,
        patient_id: patientID,
      },
    };
    axios(config)
      .then(() => {
        console.log("coach assigned");
        fetchAllUsers(true);
      })
      .catch((err) => console.log("error while assigning the coach: ", err));
  };

  const removeCoach = (doctorID, patientID) => {
    console.log("remove doc: ", doctorID, patientID);
    let config = {
      method: "delete",
      url: process.env.REACT_APP_BACKEND_URL + "patients/providers",
      headers: {
        Authorization: "JWT " + token,
        x_firebase_id: patientID,
        "Content-Type": "application/json",
      },
      data: {
        doctor_id: doctorID,
        patient_id: patientID,
      },
    };
    axios(config)
      .then(() => {
        console.log("coach removed");
        fetchAllUsers(true);
      })
      .catch((err) => console.log("error while deleting the coach: ", err));
  };

  const handleStartProg = (user, programType) => {
    let data = {
      program_details: {
        rapilot: {
          start_date: new Date(),
        }
      }
    }
    let config = {
      method: 'put',
      url: process.env.REACT_APP_BACKEND_URL + 'users',
      headers: {x_firebase_id: user.firebase_id, Authorization: 'JWT ' + token},
      data: data
    }
    axios(config)
    .then(() => {
      console.log('program started')
      notification.open({
        message: 'Program',
        description: 'Program has started started for the user!',
      });
    })
    .catch((err) => console.log('error while starting program: ', err))
  }


  const addCommunity = (communityID, patientID) => {
    console.log("add COMMUNITY: ", communityID, patientID);
    let config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "communities/user",
      headers: {
        Authorization: "JWT " + token,
      },
      data: {
        firebase_id: patientID,
        community_id: communityID,
      },
    };
    axios(config)
      .then(() => {
        console.log("COMMUNITY assigned");
        fetchAllUsers(true);
        // let temp = userCoach;
        // let index = userCoach.findIndex(
        //   (user) => user.firebase_id === patientID
        // );
        // temp[index].communities
        //   .filter((comm) => comm._id === communityID)
        //   .forEach((community) => {
        //     community.users[patientID] = true;
        //   });
      })
      .catch((err) =>
        console.log("error while assigning the COMMUNITY: ", err)
      );
  };

  const delCommunity = (communityID, patientID) => {
    console.log("DELETE COMMUNITY: ", communityID, patientID);
    let config = {
      method: "delete",
      url: process.env.REACT_APP_BACKEND_URL + "communities/user",
      headers: {
        Authorization: "JWT " + token,
        "Content-Type": "application/json",
      },
      data: {
        firebase_id: patientID,
        community_id: communityID,
      },
    };
    axios(config)
      .then(() => {
        console.log("COMMUNITY DELETED");
        fetchAllUsers(true);

        // let temp = userCoach;
        // let index = userCoach.findIndex(
        //   (user) => user.firebase_id === patientID
        // );
        // temp[index].communities
        //   .filter((comm) => comm._id === communityID)
        //   .forEach((community) => {
        //     community.users[patientID] = false;
        //   });
      })
      .catch((err) => console.log("error while DEL the COMMUNITY: ", err));
  };

  const handleEduProgChange = (patientID, programSelected, coaching) => {
    let data = { coaching };
    data.coaching.program = programSelected;
    let config = {
      method: "put",
      url: process.env.REACT_APP_BACKEND_URL + "users",
      headers: {
        Authorization: "JWT " + token,
        x_firebase_id: patientID,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(() => {
        console.log("program changed");
        fetchAllUsers(true);
      })
      .catch((err) => console.log("error while changing the program: ", err));
  };

  const updateSuggesticData = () => {
    let suggest = {};
    var promises = [];
    var mergedSuggest = [];
    userCoach.forEach((user, index) => {
      if (user.suggestic && user.suggestic.user_id) {
        // to fetch the suggestic data push all the request into promises
        promises.push(
          axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "suggestic/users",
            headers: {
              sg_user_id: user.suggestic.user_id,
              Authorization: "JWT " + token,
            },
          })
            .then((res) => {
              let sg_user_id = res.config.headers.sg_user_id; // sg_user_id not available in the pgm data so fetch it from res headers
              suggest[sg_user_id] = {
                sg_user_id: sg_user_id,
                data: res.data.myProfile.program,
              }; // indexing to map
            })
            .catch((err) => console.log("err in suggestic", err))
        );
      }
    });
    Promise.all(promises).then(() => {
      // to map the suggestic programs with respc users
      userCoach.forEach((data, index) => {
        mergedSuggest.push({
          ...data,
          ...(data.suggestic &&
            data.suggestic.user_id && {
              suggestic_program: suggest[data.suggestic.user_id],
            }),
        });
      });
      console.log("suggestic data updated");
      // update the merged suggestic program data to the user coach
      mergedSuggest.length && setUserCoach(mergedSuggest);
    });
  };

  const ProgramDropdown = ({ list, sugData }) => {
    // console.log(sugData)
    return (
      <Select
        defaultValue="change prgm"
        style={{ width: 160 }}
        onChange={(value) => handlePrgmChange(value, sugData)}
      >
        {list.map((prg) => {
          return <Option value={prg.node.id}>{prg.node.name}</Option>;
        })}
      </Select>
    );
  };

  // const getProgramList = (sg_user_id) => {
  //   console.log(sg_user_id)
  //   axios({
  //     method: 'get',
  //     url: process.env.REACT_APP_BACKEND_URL + 'suggestic/programs',
  //     headers: {sg_user_id: sg_user_id, Authorization: 'JWT ' + token}
  //   })
  //   .then(res => {
  //     // console.log(res.data.response.programs.edges)
  //     if (res.data && res.data.response) {
  //       let col = {
  //         title: 'Change Prgm',
  //         dataIndex: 'suggestic_program',
  //         key: 'change',
  //         width: 200,
  //         render: (suggestic_program) =>
  //           (
  //             <ProgramDropdown list={res.data.response.programs.edges} sugData={suggestic_program} />
  //           )
  //       }
  //       setCoachColumns([...coachColumnsRef.current, col])
  //     }
  //   })
  //   .catch(err => console.log('err in fetching suggestic pgm list', err))
  // }

  // to fetch all the providers names and other details required for assigning coaches
  const getAllCoachesData = async () => {
    try {
      let data = axios({
        method: "get",
        url: process.env.REACT_APP_BACKEND_URL + "providers",
        headers: {
          x_firebase_id: currentUser.uid,
          Authorization: "JWT " + token,
          getallproviders: true,
        },
      });
      return data;
    } catch (err) {
      console.log("err in fetching assigned coaches", err);
      return {};
    }
  };

  // to fetch the coaches assigned for the respective patients
  const getCoachAssigned = async (patientIdArray) => {
    try {
      let data = axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "patients/providers",
        headers: {
          x_firebase_id: currentUser.uid,
          Authorization: "JWT " + token,
        },
        data: {
          patientIdArray: patientIdArray,
        },
      });
      return data;
    } catch (err) {
      console.log("err in fetching assigned coaches", err);
      return {};
    }
  };

  const getCommunities = async (patientIdArray) => {
    try {
      let data = axios({
        method: "get",
        url: process.env.REACT_APP_BACKEND_URL + "communities",
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: currentUser.uid,
        },
      });
      return data;
    } catch (err) {
      console.log("error when getting communtieis", err);
      return {};
    }

    // try {
    //   let data = axios({
    //     method: "get",
    //     url: process.env.REACT_APP_BACKEND_URL + "communities",
    //     headers: {
    //       Authorization: `JWT ${token}`,
    //       x_firebase_id: patientUid,
    //     },
    //   });
    //   return data;
    // } catch (err) {
    //   console.log("error when getting communtieis", err);
    //   return {};
    // }
  };

  const fetchEducation = async () => {
    // get login timestamps
    const { data: data, error: error } = await getEducationUpdates({
      firebaseId: currentUser.uid,
      token: token,
    });
    if (!error) {
      setEducation(data);
    } else {
      console.log("error getting education content ");
    }
  };

  const fetchAllUsers = async () => {
    setLoading(true);
    if(!token) await getAuthToken(currentUser);
    // get all info about the coaching active users
    const { data: userData, error: userDataError } = await getAllUserCoach({
      firebaseId: currentUser.uid,
      token: token,
    });
    // get all latest patient appointment info of paid users
    const { data: userAppointmentData, error: userAppointmentDataError } =
      await getUserCoachAppointment({
        firebaseId: currentUser.uid,
        token: token,
      });
    // get all messages b/w patients and coach
    const { data: userMessageData, error: userMessageDataError } =
      await getAllUserMessages({ firebaseId: currentUser.uid, token: token });
    // get login timestamps
    const { data: userTimeStamp, error: timeStampError } =
      await getAllUserLoginTime({ firebaseId: currentUser.uid, token: token });
    if (!userDataError && !userAppointmentDataError && !userMessageDataError) {
      let patientIds = [];
      let mergedData = [];
      let suggest = {};
      var promises = [];
      var mergedSuggest = [];
      userData.forEach((user, index) => {
        let patientState = "";
        if (user.addressComponents && user.addressComponents.length > 0) {
          user.addressComponents.forEach((addressComponent) => {
            let types = addressComponent.types || [];
            if (types.includes("administrative_area_level_1"))
              patientState = addressComponent.long_name;
          });
        }
        patientIds.push(user.firebase_id);
        if (user.suggestic && user.suggestic.user_id) {
          // to fetch the suggestic data push all the request into promises
          promises.push(
            axios({
              method: "get",
              url: process.env.REACT_APP_BACKEND_URL + "suggestic/users",
              headers: {
                sg_user_id: user.suggestic.user_id,
                Authorization: "JWT " + token,
              },
            })
              .then((res) => {
                let sg_user_id = res.config.headers.sg_user_id; // sg_user_id not available in the pgm data so fetch it from res headers
                suggest[sg_user_id] = {
                  sg_user_id: sg_user_id,
                  data: res.data.myProfile.program,
                }; // indexing to map
              })
              .catch((err) => console.log("err in suggestic", err))
          );
        }

        // merging user data with appointment, message data
        mergedData.push({
          ...user,
          patientState: patientState,
          appointment: userAppointmentData.appointment[user.firebase_id],
          userMessages: userMessageData.messages[user.firebase_id],
          loginTimeStamp: userTimeStamp.timestamp[user.firebase_id],
        });
      });

      const { data: patientCoachingData } = await getCoachAssigned(patientIds);
      const { data: allCoachesData } = await getAllCoachesData(patientIds);
      const { data: patientCommunities } = await getCommunities(patientIds);

      // if(!reRender)
      //   renderCoachAssignedColumn(allCoachesData, patientCoachingData)

      // if(!reRender)
      //   renderEduProgColumn()

      mergedData = mergedData.map((user) => ({
        ...user,
        coachAssigned: patientCoachingData[user.firebase_id],
        allCoachesData: allCoachesData,
        communities: patientCommunities.otherCommunities,
      }));

      // resolve all the suggestic promises
      Promise.all(promises).then(() => {
        // to map the suggestic programs with respc users
        mergedData.forEach((data, index) => {
          mergedSuggest.push({
            ...data,
            ...(data.suggestic &&
              data.suggestic.user_id && {
                suggestic_program: suggest[data.suggestic.user_id],
              }),
          });
        });
        console.log("suggestic data loaded");
        // update the merged suggestic program data to the user coach
        mergedSuggest.length && setUserCoach(mergedSuggest);
        // if(!reRender)
        //   getProgramList(Object.keys(suggest)[0])
      });
      setUserCoach(mergedData);
      setLoading(false);
    }
  };

  const expandedRowRender = (record) => {
    let data = [];
    let educationData = [];

    // SET TABLE DATA FOR SURVEYS
    let names = [];
    record?.program_details?.rapilot?.surveys &&
      Object.entries(record?.program_details?.rapilot?.surveys).forEach(
        ([key1, value]) => {
          names = Object.keys(value);
        }
      );
    names &&
      names.forEach((name, index) => {
        let entry = {};
        entry.key = index;
        entry.name = name;
        record?.program_details?.rapilot?.surveys &&
          Object.entries(record?.program_details?.rapilot?.surveys).forEach(
            ([key1, value]) => {
              entry["week" + key1] = value[name];
            }
          );
        data.push(entry);
      });

    //SET TABLE DATA FOR EDUCATION
    let entry = {};
    entry.key = record.firebase_id;
    entry.name = "RA Program";

    education.forEach((edu, index) => {
      entry["week" + index] =
        edu.patients && edu.patients[record.firebase_id]
          ? edu.patients[record.firebase_id].read
          : false;
    });

    educationData.push(entry);

    const columns = [
      {
        title: "Questionnaire",
        children: [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 150,
          },
          {
            title: "Week 1",
            dataIndex: "week0",
            key: "w0",
            width: 50,
            render: (week0, record) => {
              return <Checkbox defaultChecked={week0}></Checkbox>;
            },
          },
          {
            title: "Week 6",
            dataIndex: "week5",
            key: "w2",
            width: 50,
            render: (week5, record) => {
              return <Checkbox defaultChecked={week5}></Checkbox>;
            },
          },
          {
            title: "Week 12",
            dataIndex: "week11",
            key: "w3",
            width: 50,
            render: (week11, record) => {
              return <Checkbox defaultChecked={week11}></Checkbox>;
            },
          },
        ],
      },
    ];

    const columns1 = [
      {
        title: "Education Content",
        children: [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 150,
          },

          {
            title: "Week 1",
            dataIndex: "week0",
            key: "week0",
            width: 50,
            render: (week0, record) => {
              return <Checkbox defaultChecked={week0}></Checkbox>;
            },
          },
          {
            title: "Week 2",
            dataIndex: "week1",
            key: "week1",
            width: 50,
            render: (week1, record) => {
              return <Checkbox defaultChecked={week1}></Checkbox>;
            },
          },
          {
            title: "Week 3",
            dataIndex: "week2",
            key: "week2",
            width: 50,
            render: (week2, record) => {
              return <Checkbox defaultChecked={week2}></Checkbox>;
            },
          },
          {
            title: "Week 4",
            dataIndex: "week3",
            key: "week3",
            width: 50,
            render: (week3, record) => {
              return <Checkbox defaultChecked={week3}></Checkbox>;
            },
          },
          {
            title: "Week 5",
            dataIndex: "week4",
            key: "w4",
            width: 50,
            render: (week4, record) => {
              return <Checkbox defaultChecked={week4}></Checkbox>;
            },
          },
          {
            title: "Week 6",
            dataIndex: "week5",
            key: "w5",
            width: 50,
            render: (week5, record) => {
              return <Checkbox defaultChecked={week5}></Checkbox>;
            },
          },
          {
            title: "Week 7",
            dataIndex: "week6",
            key: "w6",
            width: 50,
            render: (week6, record) => {
              return <Checkbox defaultChecked={week6}></Checkbox>;
            },
          },
          {
            title: "Week 8",
            dataIndex: "week7",
            key: "w7",
            width: 50,
            render: (week7, record) => {
              return <Checkbox defaultChecked={week7}></Checkbox>;
            },
          },
          {
            title: "Week 9",
            dataIndex: "week8",
            key: "w8",
            width: 50,
            render: (week8, record) => {
              return <Checkbox defaultChecked={week8}></Checkbox>;
            },
          },
          {
            title: "Week 10",
            dataIndex: "week9",
            key: "w9",
            width: 50,
            render: (week9, record) => {
              return <Checkbox defaultChecked={week9}></Checkbox>;
            },
          },
          {
            title: "Week 11",
            dataIndex: "week10",
            key: "w10",
            width: 50,
            render: (week10, record) => {
              return <Checkbox defaultChecked={week10}></Checkbox>;
            },
          },
        ],
      },
    ];

    return (
      <>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
        />

        <Table
          columns={columns1}
          dataSource={educationData}
          pagination={false}
          bordered
        />
      </>
    );
  };

  useEffect(() => {
    fetchEducation();
    fetchAllUsers();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Table
        columns={coachUsersColumn(
          handleEduProgChange,
          addCoach,
          addCommunity,
          delCommunity,
          removeCoach,
          searchByEmail,
          handleStartProg
        )}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record.program_details,
        }}
        rowKey={(record) => record._id}
        dataSource={filterByNameEmail(userCoach)}
        scroll={{ x: "100%" }}
        pagination={{ pageSize: 8 }}
        bordered
      />
    </div>
  );
}
