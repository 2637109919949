import axios from "axios";
import { db } from "../services/firebase";
import { resolve } from "./resolve";
import moment from "moment";

// To Get All users from Firestore
export async function getAllUsersFireStore() {
  const users = db.collection("users");
  return await resolve(
    users
      .orderBy("lastSignedIn", "desc")
      .get()
      .then((querySnapshot) => {
        const tempDoc = [];
        let dailyActive = 0;
        let monthlyActive = 0;
        querySnapshot.forEach((doc) => {
          tempDoc.push(doc.data());
        });
        return { tableData: tempDoc };
      })
  );
}

export const getAllCoachesData = async ({ firebaseId, token }) => {
  try {
    return await resolve(
      axios({
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "providers",
      headers: {
        x_firebase_id: firebaseId,
        Authorization: "JWT " + token,
        getallproviders: true,
      },
    }).then((res) => res.data));
  } catch (err) {
    console.log("err in fetching assigned coaches", err);
    return {};
  }
};

// To get all users from mongo backend
export async function getAllUsersBackEnd({ firebaseId, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "admin/portal/users";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// To get all users from mongo backend based on dates
export async function getAllData({ firebaseId, token, start, end }) {
  let url = process.env.REACT_APP_BACKEND_URL + "analytics/users";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: {
        x_firebase_id: firebaseId,
        Authorization: "JWT " + token,
        start_date: start,
        end_date: end,
      },
    }).then((res) => res.data)
  );
}

// To get all users from mongo backend based on dates
export async function getAllAppointmentData({ firebaseId, token, start, end }) {
  let url = process.env.REACT_APP_BACKEND_URL + "analytics/appointments";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: {
        x_firebase_id: firebaseId,
        Authorization: "JWT " + token,
        start_date: start,
        end_date: end,
      },
    }).then((res) => res.data)
  );
}

// To get coach data
export async function getAllUserCoach({ firebaseId, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "admin/portal/usercoach";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// To get coach last appointment date
export async function getUserCoachAppointment({ firebaseId, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "admin/portal/userappointment";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// To get messages data
export async function getAllUserMessages({ firebaseId, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "admin/portal/usermessages";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// To get login timestamp data
export async function getAllUserLoginTime({ firebaseId, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "admin/portal/logintimestamp";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// To get suggestic data
export async function getUserSuggestic({ sg_user_id, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "suggestic/users";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { sg_user_id: sg_user_id, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// To update suggestic program of individual user
export async function updateUserProgram({ sg_user_id, program_id, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "suggestic/users/programs";
  return await resolve(
    axios({
      method: "post",
      url: url,
      headers: {
        sg_user_id: sg_user_id,
        program_id: program_id,
        Authorization: "JWT " + token,
      },
    }).then((res) => res.data)
  );
}

// To get suggestic data
export async function getAllEvents({ x_firebase_id, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "circles";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: x_firebase_id, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// Get All scheduled EMAILS
export async function getAllEmails({ x_firebase_id, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "emails/schedule";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: x_firebase_id, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}

// To get support requests
export async function getAllSupportTickets({ firebaseId, token }) {
  let url = process.env.REACT_APP_BACKEND_URL + "support-request";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: {x_firebase_id: firebaseId, Authorization: 'JWT ' + token, admin: true}
    })
    .then(res => res.data)
  )
}

// To get support requests
export async function getEducationUpdates({ firebaseId, token }) {
  let url =
    process.env.REACT_APP_BACKEND_URL +
    "education/content?category=Condition Education&subcategory=Rheumatoid Arthritis";
  return await resolve(
    axios({
      method: "get",
      url: url,
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    }).then((res) => res.data)
  );
}
