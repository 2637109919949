import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BarChartOutlined,
  LineChartOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  PhoneOutlined,
  LogoutOutlined,
  FormOutlined,
  MailOutlined,
  CalendarOutlined,
  DollarOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import AilaLogo from "../../assets/images/aila_logo.png";
import Analytics from "../../pages/Analytics/Analytics";
import NewAnalytics from "../../pages/Analytics/NewAnalytics";
import UsersAnalytics from "../../pages/Analytics/UsersAnalytics";
import Coaching from "../../pages/Coaching/CoachingCenter";
import Events from "../../pages/Events/Events";
import Emails from "../../pages/Emails/Emails";
import AppointmentList from "../../pages/Provider/AppointmentList";
import Referrals from "../../pages/Referrals/Referrals";
import LiveCircle from "../../pages/Events/LiveCircleVideo";
import Support from "../../pages/Support/Support";
import Financials from "../../pages/Financials/Financials";
import ReportedItems from "../../pages/Reports/ReportedItems";
import { useAuth } from "../../context/AuthContext";
import PrivateRoute from "../../routes/PrivateRoute";

const { Header, Sider, Content } = Layout;

export default function Home() {
  const { logout, currentUser, getAuthToken } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  async function handleLogOut() {
    try {
      await logout();
      console.log("logged out");
      history.push("/login");
    } catch (err) {
      console.log(err);
    }
  }

  const history = useHistory();

  // useEffect(() => {
  //   getAuthToken(currentUser)
  // }, []);

  return (
    <Router>
      <Layout style={{ minHeight: "100vh", overflow: "auto" }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <a>
            <div
              onClick={toggle}
              className="logo"
              style={{
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {collapsed && (
                <img src={AilaLogo} style={{ width: 50, height: 50 }} />
              )}
              {!collapsed && (
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    color: "#FFFFFF",
                    paddingTop: 10,
                  }}
                >
                  Aila Health
                </h1>
              )}
            </div>
          </a>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            style={{ fontSize: 18, display: "flex", flexDirection: "column" }}
          >
            <Menu.Item
              key="1"
              icon={<BarChartOutlined style={{ fontSize: 18 }} />}
            >
              Analytics
              <Link to="/home" />
            </Menu.Item>
            <Menu.Item
              key="old"
              icon={<LineChartOutlined style={{ fontSize: 18 }} />}
            >
              Analytics - 2
              <Link to="/oldhome" />
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<UnorderedListOutlined style={{ fontSize: 18 }} />}
            >
              Patient List
              <Link to="/patient-list" />
            </Menu.Item>
            <Menu.Item key="3" icon={<TeamOutlined style={{ fontSize: 18 }} />}>
              Coaching
              <Link to="/coaching" />
            </Menu.Item>
            <Menu.Item key="4" icon={<TeamOutlined style={{ fontSize: 18 }} />}>
              Events
              <Link to="/events" />
            </Menu.Item>
            <Menu.Item key="5" icon={<FormOutlined style={{ fontSize: 18 }} />}>
              Referrals
              <Link to="/referrals" />
            </Menu.Item>
            <Menu.Item key="6" icon={<MailOutlined style={{ fontSize: 18 }} />}>
              Emails
              <Link to="/emails" />
            </Menu.Item>
            <Menu.Item key="7" icon={<CalendarOutlined style={{ fontSize: 18 }} />}>
              Appointments
              <Link to="/appointmentList" />
            </Menu.Item>
            <Menu.Item
              key="8"
              icon={<PhoneOutlined style={{ fontSize: 18 }} />}
            >
              Support
              <Link to="/support" />
            </Menu.Item>
            { (
                currentUser.uid === '52FeAj8HPuMPQqcLWN2rmos88Xb2' || 
                currentUser.uid === 'YjWKnuJmeGbu19vjoJAt4aZ1fFm2'||
                currentUser.uid === 'vp3pknTPdKcsABD1FNqWOr17lDi1'
                ) &&
              <Menu.Item
                key="9"
                icon={<DollarOutlined style={{ fontSize: 18 }} />}
              >
                Financials
                <Link to="/financials" />
              </Menu.Item>
            }
            <Menu.Item
              key="10"
              icon={<StopOutlined style={{ fontSize: 18 }} />}
            >
              Reported
              <Link to="/reports" />
            </Menu.Item>
            <Menu.Item
              key="logout"
              icon={<LogoutOutlined />}
              style={{ fontSize: 18 }}
              onClick={() => {
                handleLogOut();
              }}
            >
              Logout
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{ minHeight: "100vh", overflow: "auto" }}
        >
          <Header
            className="site-layout-background"
            style={{ paddingLeft: 10, color: "#FFFFFF" }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: toggle,
              }
            )}
          </Header>

          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              height: "80vh",
            }}
          >
            <Switch>
              <PrivateRoute exact path="/home">
                <NewAnalytics />
              </PrivateRoute>
              <PrivateRoute exact path="/oldhome">
                <Analytics />
              </PrivateRoute>
              <PrivateRoute exact path="/patient-list">
                <UsersAnalytics />
              </PrivateRoute>
              <PrivateRoute exact path="/coaching">
                <Coaching />
              </PrivateRoute>
              <PrivateRoute exact path="/events">
                <Events />
              </PrivateRoute>
              <PrivateRoute exact path="/referrals">
                <Referrals />
              </PrivateRoute>
              <PrivateRoute exact path="/emails">
                <Emails />
              </PrivateRoute>
              <PrivateRoute exact path="/appointmentList">
                <AppointmentList />
              </PrivateRoute>
              <PrivateRoute exact path="/events/live-circle">
                <LiveCircle />
              </PrivateRoute>
              <PrivateRoute exact path="/support">
                <Support />
              </PrivateRoute>
              <PrivateRoute exact path="/financials">
                <Financials />
              </PrivateRoute>
              <PrivateRoute exact path="/reports">
                <ReportedItems />
              </PrivateRoute>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}
