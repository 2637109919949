import React, {useEffect, useState} from 'react'
import { useLocation } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import {
  Button,
  Tabs,
  Table,
  Tag,
  Space,
  Row,
  Col,
  notification
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophoneSlash, faVideoSlash, faPhone, faPhoneSlash, faTrash, faPlusCircle, faVideo, faMicrophone, faAlignJustify} from "@fortawesome/free-solid-svg-icons";
import Loading from '../../components/Loading';
const TWILIO_VIDEO = require('twilio-video')

export default function LiveCircleVideo() {
  const { token, currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [videoCallRoom, setVideoCallRoom] = useState(null);
  const [videoCallJoined, setVideoCallJoined] = useState(false);
  const [mute, setMute] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);
  const [twilioTokenString, setTwilioToken] = useState(null);
  const [circleSid, setCircleSid] = useState(null);
  const [dominantSpeaker, setDominantSpeaker] = useState(videoCallRoom?.dominantSpeaker);
  // console.log('TESTING DOMINANT SPEAKER: ', dominantSpeaker)

  const history = useHistory();
  const location = useLocation()
  const { circle } = location
  // console.log('circle ID: ', circle._id)


  useEffect(() => {
    if(circle._id) {
      createLocalVideoStream(circle)
    }
  }, [])

  useEffect(() => {
    if(!videoCallRoom)
    return
    videoCallRoom.on('dominantSpeakerChanged', participant => {
      setDominantSpeaker(participant);
      const domDiv = document.getElementById('dominant');
      participant?.tracks.forEach(publication => {
        // console.log('inside the dominant about if', )
        if(publication?.kind === 'video') {
          // console.log('inside the dominant inside if')
          // console.log('we can attach', JSON.stringify(publication?.track), JSON.stringify(publication?.track.attach()) )
          let wrapperDiv = document.createElement('div')
          wrapperDiv.style.cssText = 'width: 350px; height: 350px; overflow: hidden';
          let dominantEle = publication?.track.attach()
          dominantEle.height = "450"
          dominantEle.width = "500"
          dominantEle.id = 'dominant'
          domDiv.parentNode.replaceChild(dominantEle, domDiv);
        }
      });
    });
    return () => {
      videoCallRoom.off('dominantSpeakerChanged', setDominantSpeaker);
    };
  }, [videoCallRoom]);


  const buttonContainer = {
    justifyContent: 'center',
    border: '1px solid #20A892',
    borderRadius: 50,
    alignContents: 'center',
    padding: 8,
    margin: 10,
    cursor: 'pointer'
  }

  if(!circle._id)
    return(<p>Invalid Circle</p>)

  const onRoomConnect = (twilioToken, roomName) => {
    console.log('FUNCTION CALLED: onRoomConnect (TWILIO_VIDEO.connect)')
    if(!twilioToken){
      console.log('Token not present')
      return
    }
    console.log('TOKEN PRESENT:::', twilioToken)

    TWILIO_VIDEO.connect(twilioToken, { name: roomName, dominantSpeaker: true }).then(room => {
      console.log('Connected to Room "%s"', room.name, room.sid);
      setCircleSid(room.sid)
      setVideoCallRoom(room)
      setVideoCallJoined(true)
      setLoading(false)
      // self.setState({videoCallJoined: true, videoCallRoom: room})

      room.participants.forEach(participantConnected);
      room.on('participantConnected', participantConnected);

      room.on('participantDisconnected', participantDisconnected);
      room.once('disconnected', error => room?.participants.forEach(participantDisconnected));
    });

    function participantConnected(participant) {
      console.log('Participant "%s" connected', participant);

      const div = document.getElementById('remote-media');

      participant?.tracks.forEach(publication => {
        // console.log('existing tracks of remote participant', JSON.stringify(publication))
        if (publication?.isSubscribed) {
          trackPublished(div,publication, participant);
        }

        publication.on('subscribed', (track) => {
          // console.log('new subscribed here', track)
          trackPublished(div,publication, participant)
        })

        publication.on('unsubscribed', (track) => {
          // console.log('unpublished bevarc')
          if (track.kind !== 'data') {
            track.detach().forEach(element => element.remove());
          }
        })
      });

      participant.on('trackPublished', publication => {
        // console.log('track published by remote participant')
        trackPublished(div,publication, participant);
      });

      participant.on('trackUnpublished', publication => {
        // console.log(`RemoteParticipant ${participant.identity} unpublished a RemoteTrack: ${publication}`);
      });
    }

    function participantDisconnected(participant) {
      console.log('Participant "%s" disconnected', participant.identity);
    }

    function trackPublished(div,publication, participant) {
      // console.log(`RemoteParticipant ${participant?.identity} published a RemoteTrack: ${JSON.stringify(publication)}`);
      if(publication?.isSubscribed && publication?.kind !== 'data') {
        // console.log('we can attach', JSON.stringify(publication?.track), JSON.stringify(publication?.track.attach()) )
        let wrapperDiv = document.createElement('div')
        wrapperDiv.style.cssText = 'width: 350px; height: 350px; overflow: hidden';
        let ele = publication?.track.attach()
        ele.height = "250"
        ele.width = "250"
        wrapperDiv.appendChild(publication?.track.attach())
        div.appendChild(ele);
      }
    }

    function appendTrack(div, track) {
      console.log('appending track to div')
      div.appendChild(track.attach());
    }

    function trackUnsubscribed(track) {
      console.log('removing track from div')
      // track.detach().forEach(element => element.remove());
    }
  }

  const createLocalVideoStream = () => {


    if(!TWILIO_VIDEO.isSupported) {
      console.log('not supported by browser')
      return
    }

    TWILIO_VIDEO.createLocalTracks({
      audio: true,
      video: { width: 300, height: 300 },
    }).then(tracks => {
      console.log('created local track')
      const localMediaContainer = document.getElementById('local-media');
      tracks.forEach(track => {
        localMediaContainer.appendChild(track.attach());
      })
      console.log('tracks added')
    });
  }

  const onMuteVideoPressed = () => {

    if(!videoCallRoom)
      return
    if(disableVideo) {
      //if its muted, unmute it
      videoCallRoom?.localParticipant?.videoTracks.forEach(publication => {
        publication.track.enable();
      });
    } else {
      videoCallRoom?.localParticipant?.videoTracks.forEach(publication => {
        publication.track.disable();
      });
    }

    setDisableVideo(!disableVideo)
  }

  const onMuteAudioPressed = () => {

    if(!videoCallRoom)
      return
    if(mute) {
      //if its muted, unmute it
      videoCallRoom?.localParticipant?.audioTracks.forEach(publication => {
        publication?.track.enable();
      });
    } else {
      videoCallRoom?.localParticipant?.audioTracks.forEach(publication => {
        publication.track.disable();
      });
    }
    setMute(!mute)
  }

  const openNotification = (alertSentence) => {
    notification.open({
      message: 'Alert',
      description: alertSentence,
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  const endCircle = circle_id => {
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}twilio/group/end-room`,
      headers: {
        x_firebase_id: currentUser.uid,
        Authorization: `JWT ${token}`,
        room_name: circle_id,
        room_sid: circleSid,
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then(response => {
        console.log('Circle ended', 'You have closed the circle.');
        openNotification('You have closed the circle for all participants.')
      })
      .catch(error => {
        console.log(error);
      });
  };

  const checkCreateRoom = (circle) => {
    setLoading(true);
    // users or host can join only 10 mins before the meeting
    // if (moment().diff(circle.scheduled_date, 'minutes') <= -10) {
    //   setLoading(false);
    //   console.log('Please wait', 'You can join 10 mins before Scheduled time');
    //   openNotification('You can join 10 mins before Scheduled time')
    //   return;
    // }
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}twilio/group/room`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: currentUser.uid,
        room_name: circle._id,
      },
    };
    console.log('API CALLED: twilio/group/room')
    axios(config).then(res => {
      // if the room is created or if the hos
      if ((res.data.isHost && res.data.room_created) || res.data.room_created) {
        console.log('GROUP ROOM CREATED (twilio/group/room)')
        getTwilioToken(circle._id, circle);
      }
      // if the room is not yet created
      else {
        setLoading(false);
        console.log(
          'Please wait',
          'Please wait for the host to start the circle',
        );
        openNotification('Please wait for the host to start the circle')
      }
    });
  };

  const getTwilioToken = (circle_id, circle) => {
    console.log('API CALLED: twilio/group/token')
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}twilio/group/token`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: currentUser.uid,
        room_name: circle_id,
      },
    };

    axios(config)
      .then(function (response) {
        response.data &&
        response.data.token &&
        console.log('TWILIO TOKEN RECEIVED FROM BACKEND from (twilio/group/token)', response.data)
        //   // setTwilioToken(response.data.token);
        // createLocalVideoStream(response.data.token, circle_id)
        onRoomConnect(response.data.token, circle_id);
        setTwilioToken(response.data.token)
      })
      .catch(function (error) {
        setLoading(false);
        console.log('error when getting twilio token', error);
      });
  };

  const onJoinCallPressed = () => {
    if(videoCallJoined) {
      //call already joined.. user wants to disconnect
      if(!videoCallRoom)
        return

      console.log('disconnecting')
      videoCallRoom.disconnect()
      setVideoCallJoined(false)
      setVideoCallRoom(null)
      return
    }
    if(circle) {
      checkCreateRoom(circle)
    }
  }

  return (
    <div>
      <Row>
        <Col span={2}/>
        <Col span={5}>
            <div style={{ display:'flex', justifyContent: "center" }}>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: 10, justifyContent: 'center', alignItems: 'center'}}>

                  <div
                    style={buttonContainer}
                    onClick={() => onMuteAudioPressed()}
                  >
                    <FontAwesomeIcon
                      icon={mute ? faMicrophoneSlash : faMicrophone}
                      style={{color: 'green', fontSize: 20, cursor: 'pointer'}}/>
                  </div>

                  <div
                    style={buttonContainer}
                    onClick={() => onJoinCallPressed()}
                  >
                    <FontAwesomeIcon
                      icon={videoCallJoined ? faPhoneSlash : faPhone}
                      style={{color: (videoCallJoined ? 'red' : 'green'), fontSize: 20, cursor: 'pointer'}}/>
                  </div>

                  <div
                    style={buttonContainer}
                    onClick={() => onMuteVideoPressed()}
                  >
                    <FontAwesomeIcon
                      icon={disableVideo ? faVideoSlash : faVideo}
                      style={{color: 'green', fontSize: 20, cursor: 'pointer'}}/>
                  </div>
                  <Button
                    onClick={()=> endCircle(circle._id)}
                  >
                    End Circle
                  </Button>
                </div>
            </div>
            <div id={'local-media'}/>
        </Col>
        <Col span={2}/>
        <Col span={14}>
          <div style={{ height: 500, width: '100%', overflow: 'auto' }}>
            <div id='dominant'/>
          </div>
        </Col>
      </Row>
      <Row>
        {
          loading
          ? <Loading/>
          : (
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', overflow: 'auto'}}>
              <div style={{marginLeft: 20, height: 300, width: (('100vw')/2.5), display: 'flex', flexDirection: 'row', overflow: 'auto' }} id={'remote-media'}/>
            </div>
          )
        }
      </Row>
    </div>
  )
}
