import React, { useEffect, useState } from "react";
import { Table, Checkbox, Tabs, Select, notification } from "antd";
import moment from "moment";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { getAllSupportTickets } from "../../api/users";
import Loading from "../../components/Loading";

const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

export default function Support() {
  const { token, currentUser, getAuthToken } = useAuth();
  // const [supportData, setSupportData] = useState([]);
  const [clinicalSupportData, setClinicalSupportData] = useState([]);
  const [techSupportData, setTechSupportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providerData, setProviderData] = useState([]);
  
  useEffect(() => {
    fetchAllTickets();
    getAllCoachesData();
  }, []);

  const getAllCoachesData = () => {
      axios({
        method: "get",
        url: process.env.REACT_APP_BACKEND_URL + "providers",
        headers: {
          x_firebase_id: currentUser.uid,
          Authorization: "JWT " + token,
          getallproviders: true,
        },
      }).then(({data}) => {
        setProviderData(data)
      }).catch(err => {
        console.log('err while loading provider data: ', err)
      })
  };

  const fetchAllTickets = async () => {
    setLoading(true);
    await getAuthToken(currentUser);
    // get all info about the coaching active users
    const { data: data, error: error } = await getAllSupportTickets({
      firebaseId: currentUser.uid,
      token: token,
    });
    if (!error) {
      let techArr = []
      let clinArr = []
      data.forEach((support) => {
        if(support.clinical_support) {
          clinArr.push(support)
        } else {
          techArr.push(support)
        }
      })
      setTechSupportData(techArr)
      setClinicalSupportData(clinArr)
      // setSupportData(data);
      setLoading(false);
    }
  };

  //Update firestore collection
  const updateSupport = async (record) => {
    let config = {
      method: "put",
      url: process.env.REACT_APP_BACKEND_URL + "support-request",
      headers: {
        x_firebase_id: currentUser.uid,
        Authorization: "JWT " + token,
      },
      data: record,
    };
    axios(config)
      .then(() => {
        console.log("support request edited");
      })
      .catch((err) => console.log("error while assigning the coach: ", err));
  };

  const handleCheckIn = async (checkedIn, record, supportData, setSupportData) => {
    let temp = supportData;
    temp &&
      temp.forEach((support) => {
        if (support._id === record._id) {
          support.checkedIn = !support.checkedIn;
        }
      });

    let tempSupport = {
      _id: record._id,
      checkedIn: record.checkedIn
    }

    setSupportData(temp);
    updateSupport(tempSupport);
  };

  const handleSupportAssign = async (doctor_id, record) => {
    let tempSupport = {
      _id: record._id,
      assigned_to: doctor_id
    }
    updateSupport(tempSupport);
  };

  const clinicalSupportColumns = [
    {
      title: "Checked In",
      dataIndex: "checkedIn",
      key: "checkin",
      width: 20,
      align: "center",
      render: (checkedIn, record) => {
        return (
          <Checkbox
            defaultChecked={checkedIn}
            onChange={() => {
              handleCheckIn(checkedIn, record, clinicalSupportData, setClinicalSupportData);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      render: (created_at, record) => {
        return <p>{moment(created_at).format("lll")}</p>;
      },
    },
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      width: 50,
    },
    {
      title: "Provider Name",
      dataIndex: "doctor_id",
      key: "providerName",
      width: 80,
      render: (doctor_id) => {
        let providerName = ''
        providerData && providerData?.find(provider => {
          if(provider?.firebase_id === doctor_id) {
            providerName = provider?.first_name + ' ' + provider?.last_name
          }
        })
        return <p>{providerName}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 50,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: 50,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: 100,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
    },
    {
      title: "Assign",
      dataIndex: "assigned_to",
      key: "assigned_to",
      width: 250,
      render: (assigned_to, record) => {
        // let providerName = ''
        // providerData && providerData?.find(provider => {
        //   if(provider?.firebase_id === doctor_id) {
        //     providerName = provider?.first_name + ' ' + provider?.last_name
        //   }
        // })
        let coachOptionList = [];
        // grouping the providers according to user_type
        let allProviderData = providerData.reduce((data, doc) => {
          data[doc.user_type || "Other"] = data[doc.user_type || "Other"] || [];
          data[doc.user_type || "Other"].push(doc);
          return data;
        }, Object.create(null));

        Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
          coachOptionList.push(
            <OptGroup label={userType}>
              {doctorArray.map((doc) => (
                <Option key={doc.firebase_id}>
                  {doc?.first_name} {doc?.last_name}
                </Option>
              ))}
            </OptGroup>
          );
        });

        return (
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder={"Select provider"}
            value={assigned_to}
            // onChange={(value) => addCoach(value, user.firebase_id)}
            onSelect={(value) => handleSupportAssign(value, record)}
          >
            {coachOptionList.reverse()}
          </Select>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "completed_status",
      key: "completed_status",
      width: 20,
      align: "center",
      render: (completed_status, record) => {
        return (
          <Checkbox
            defaultChecked={completed_status}
            // onChange={() => {
            //   handleCheckIn(checkedIn, record, clinicalSupportData, setClinicalSupportData);
            // }}
          ></Checkbox>
        );
      },
    }
  ];

  const techSupportColumns = [
    {
      title: "Checked In",
      dataIndex: "checkedIn",
      key: "checkin",
      width: 20,
      align: "center",
      render: (checkedIn, record) => {
        return (
          <Checkbox
            defaultChecked={checkedIn}
            onChange={() => {
              handleCheckIn(checkedIn, record, techSupportData, setTechSupportData);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      render: (created_at, record) => {
        return <p>{moment(created_at).format("lll")}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 50,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 50,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: 50,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: 100,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Clinical Support" key="1">
          <Table
            columns={clinicalSupportColumns}
            bordered
            dataSource={clinicalSupportData}
            rowKey={(record) => record._id}
            scroll={{ x: "100%" }}
            pagination={{ pageSize: 8 }}
          />
        </TabPane>
        <TabPane tab="Technical Support" key="2">
          <Table
            columns={techSupportColumns}
            bordered
            dataSource={techSupportData}
            rowKey={(record) => record._id}
            scroll={{ x: "100%" }}
            pagination={{ pageSize: 8 }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
