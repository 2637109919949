import React, { useState } from 'react';
import { Form, Input, Button, Row, Alert, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import AilaLogo from '../assets/images/aila_logo.png';
import { useAuth } from '../context/AuthContext';
import { Link, useHistory } from 'react-router-dom';

export default function Login() {
  const { login, currentUser, getAuthToken, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorString, setErrorString] = useState('')
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let {userData, errorLogin} = await login(values.email, values.password);
      // await setCurrentUser(userData.user);
      if (!errorLogin){
        history.push('/home');
        let tokenResp = await getAuthToken(userData.user);
        if (tokenResp.error === true){
          setError(true)
          setErrorString('Token Error')
        }
      }
      else {
        setError(true);
        setErrorString('You Do Not Have Access!!')
      }
    } catch(err) {
      setError(true);
      setErrorString(err.message)
      console.log(err)
      onFinishFailed(err.message);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{alignItems: 'center', marginTop: '20vh'}}>
    <Row style={{justifyContent: 'center'}}>
      { loading ?
        <Spin size="large" />
        :
        <img src={AilaLogo} style={{height: 100, width: 100}}/>
      }
    </Row>
    <Row  style={{justifyContent: 'center'}}>
      <h1 style={{textAlign: 'center', marginTop: 10}}>Welcome to Aila Health Admin Portal</h1>
    </Row>
    { error &&
      <Row  style={{justifyContent: 'center'}}>
        <Alert message={errorString} type="error" />
      </Row>
    }
    <Row  style={{justifyContent: 'center', marginTop: 40 }}>
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Please input your email!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Row style={{justifyContent: 'center'}}>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{backgroundColor: '#20A892', borderColor: '#20A892'}} disabled={loading} >
          Login
        </Button>
      </Form.Item>
      </Row>
    </Form>
    </Row>
    </div>
  );
}
