import React, { useEffect, useState } from "react";
import {
  Button,
  Tabs,
  Table,
  Tag,
  Space,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Result,
} from "antd";
import moment from "moment";
// import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import { getAllEmails } from "../../api/users";
import Loading from "../../components/Loading";
import axios from "axios";
import {
  multiEmailsColumn,
  testEmailsColumn,
} from "../../components/Emails/emailsColumn";

const { TabPane } = Tabs;

const { Option } = Select;
const OptionList = [
  { value: "Basic", key: "basic" },
  { value: "Basic 7 days", key: "basic7" },
  { value: "Basic 30 days", key: "basic30" },
  { value: "Membership", key: "membership" },
  { value: "All Access", key: "premium" },
  { value: "All Users", key: "allusers" },
  { value: "Long Covid", key: "long_covid" },
  { value: "California", key: "CA" },
  { value: "Colorado", key: "CO" },
  { value: "Texas", key: "TX" },
];

export default function Emails() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [audience, setAudience] = useState([]);
  const [scheduleTime, setScheduleTime] = useState(moment(new Date()));
  const [idTemplate, setIdTemplate] = useState("");
  const [multiData, setMultiData] = useState([]);
  const [emailTest, setEmailTest] = useState("");
  const [templateTest, setTemplateTest] = useState("");
  const [testTable, setTestTable] = useState([]);
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateSubjectTest, setTemplateSubjectTest] = useState("");

  const [error, setError] = useState(false);
  const [errorString, setErrorString] = useState("");

  useEffect(() => {
    fetchAllEmails();
  }, []);

  const fetchAllEmails = async () => {
    setLoading(true);
    if(!token) await getAuthToken(currentUser);

    //get all the scheduled emails
    const { data: scheduledEmails, error: backendDataError } =
      await getAllEmails({ firebaseId: currentUser.uid, token: token });

    if (!backendDataError) {
      //set the data source of the scheduled emails Table
      setMultiData(scheduledEmails);

      //set datasource for Input data Table
      setTestTable([
        {
          key: "1",
          audience: [],
          templateId: "",
          templateSubject: templateSubjectTest,
          scheduleTime: "",
          test: true,
        },
        {
          key: "2",
          audience: [],
          templateId: "",
          templateSubject: templateSubject,
          scheduleTime: "",
        },
      ]);
      setLoading(false);
    }
  };

  // to save the scheduled email in the backend
  const deleteScheduledEmail = async (body) => {
    try {
      let data = axios({
        method: "delete",
        url: process.env.REACT_APP_BACKEND_URL + "emails/schedule",
        headers: {
          x_firebase_id: currentUser.uid,
          Authorization: "JWT " + token,
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("err in scheduling emails", err);
      return {};
    }
  };

  const handleDeleteEmail = async (templateId, record) => {
    const result = await deleteScheduledEmail(record);
    fetchAllEmails();
  };

  // to save the scheduled email in the backend
  const postScheduledEmail = async (body) => {
    try {
      let data = axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "emails/schedule",
        headers: {
          x_firebase_id: currentUser.uid,
          Authorization: "JWT " + token,
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("err in scheduling emails", err);
      return {};
    }
  };

  const handleSendEmail = async (record) => {
    setError(false);
    setErrorString("");
    if (record?.test) {
      if (emailTest.length === 0 || templateTest.length === 0) {
        setError(true);
        setErrorString("Please enter the test email ID and email template ID");
        return;
      }
      if (scheduleTime < moment(new Date())) {
        // console.log(moment.duration(moment(new Date()).diff(scheduleTime)));
        setError(true);
        setErrorString(
          "The scheduled time has passed, Please check the scheduled date and time"
        );
        return;
      }
      var response = {
        key: Math.random(),
        testEmail: emailTest,
        audience: [emailTest],
        scheduleTime: scheduleTime,
        templateId: templateTest,
        templateSubject: templateSubjectTest,        
        offset: moment(scheduleTime).utcOffset(),
      };
    } else {
      if (audience.length === 0 || idTemplate.length === 0) {
        setError(true);
        setErrorString(
          "Please select the audience and enter the email template ID"
        );
        return;
      }
      if (scheduleTime < moment(new Date())) {
        setError(true);
        setErrorString(
          "The scheduled time has passed, Please check the scheduled date and time"
        );
        return;
      }
      var response = {
        key: Math.random(),
        audience: audience,
        scheduleTime: scheduleTime,
        templateId: idTemplate,
        templateSubject: templateSubject,
        offset: moment(scheduleTime).utcOffset(),
      };
    }
    setLoading(true);
    setError(false);
    setErrorString("");

    const result = await postScheduledEmail(response);

    setTemplateTest("");
    setTemplateSubject("")
    setTemplateSubjectTest("")
    setEmailTest("");
    setIdTemplate("");
    setAudience([]);
    setScheduleTime(moment(new Date()));

    fetchAllEmails();
  };

  // to save the scheduled email in the backend
  const getTemplateSubject = async (body, test) => {
    try {
      axios({
        method: "get",
        url: process.env.REACT_APP_BACKEND_URL + `emails/template?id=${body}`,
        headers: {
          x_firebase_id: currentUser.uid,
          Authorization: "JWT " + token,
        },
      })
        .then((result) => {
          // console.log("TEMP DATA::::", result.data.versions[0].subject);
          test
            ? setTemplateSubjectTest(result.data.versions[0].subject)
            : setTemplateSubject(result.data.versions[0].subject);
        })
        .catch((err) => {
          test ? setTemplateSubjectTest("") : setTemplateSubject("");
          console.log("ERROR TEMPLATE:::", err);
        });
    } catch (err) {
      console.log("err in scheduling emails", err);
      return {};
    }
  };

  const subjectInputTest = <p>{templateSubjectTest}</p>;
  const subjectInput = <p>{templateSubject}</p>;

  const emailTemplateInput = (
    <Input
      placeholder="Email Template ID"
      value={idTemplate}
      onChange={(e) => {
        const email = e.target.value;
        setIdTemplate(email);
        getTemplateSubject(email, false);
      }}
    />
  );

  const audienceInput = (
    <Select
      mode="multiple"
      allowClear
      style={{ width: "100%" }}
      placeholder="Select Audience"
      defaultValue={audience}
      onChange={(value) => {
        setAudience(value);
      }}
    >
      {OptionList.map((prog) => (
        <Option value={prog.key}>{prog.value}</Option>
      ))}
    </Select>
  );

  const scheduleInput = (
    <DatePicker
      showTime
      defaultValue={scheduleTime}
      disabledDate={(current) => {
        return moment().add(-1, "days") >= current;
      }}
      onChange={(date, dateString) => setScheduleTime(date)}
    />
  );

  const testTemplateInput = (
    <Input
      placeholder="Enter Test Template ID"
      value={templateTest}
      onChange={(e) => {
        const id = e.target.value;
        setTemplateTest(id);
        getTemplateSubject(id, true);
      }}
    />
  );

  const testEmailInput = (
    <Input
      placeholder="Test Email ID"
      value={emailTest}
      onChange={(e) => {
        const email = e.target.value;
        setEmailTest(email);
      }}
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Table
        columns={testEmailsColumn(
          subjectInputTest,
          subjectInput,
          testTemplateInput,
          testEmailInput,
          emailTemplateInput,
          audienceInput,
          scheduleInput,
          handleSendEmail
        )}
        dataSource={testTable}
        bordered
        pagination={false}
      />
      <br />
      {error && (
        <p style={{ color: "red" }}>
          {errorString} <br />{" "}
        </p>
      )}
      <Table
        columns={multiEmailsColumn(handleDeleteEmail)}
        dataSource={multiData}
        bordered
      />
    </>
  );
}
