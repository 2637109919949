import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, validate } from '../services/firebase';
import axios from 'axios';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);

  // Token from backend
  const getAuthToken = async (currentUser) => {
    if(!currentUser) {
      console.log('Invalid user')
      return ({error: true})
    }

    let authUrl = process.env.REACT_APP_BACKEND_URL + 'authorization'
    try {
      let response = await axios({
        method: 'get',
        url: authUrl,
        headers: {x_firebase_id: currentUser.uid}
      })
      if(response.status === 200) {
        setToken(response.data.token);
        return ({error: false})
      } else {
        console.log('error', response);
        return ({error: true})
      }
    }catch(err) {
      console.log('error when getting auth token', err);
      return ({error: true, errorString: 'error when getting auth token'})
    }
  }

  // Firebase Login
  async function login(email, password) {
    var user = await auth.signInWithEmailAndPassword(email, password);
    var error = false;
    if (validate.includes(user.user.uid)){
      setCurrentUser(user.user);
    }
    else{
      error = true
    }
    return {userData: user, errorLogin: error}
  }

  // Firebase Logout
  function logout() {
    setToken(null);
    return auth.signOut();
  }

  const value = {
    currentUser,
    token,
    loading,
    setLoading,
    getAuthToken,
    setToken,
    setCurrentUser,
    login,
    logout,
  }
  
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
