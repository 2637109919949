import React, { useEffect, useState } from "react";
import { Button, Tabs, Table, Tag, Space, Row, Col, Input } from "antd";
import moment from "moment";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import {
  ReferenceLine,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  allUsersColumn,
  paidUsersColumn,
} from "../../components/Analytics/columns";
import { getAllUsersBackEnd, getAllUserLoginTime } from "../../api/users";
import StatusCard from "../../components/Analytics/StatusCard";
import Loading from "../../components/Loading";
import axios from "axios";
import { db, superUsers } from "../../services/firebase";
import AnalyticsTable from "./AnalyticsTable";

const { TabPane } = Tabs;

export default function UsersAnalytics() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [allUsersData, setAllUsersData] = useState([]);
  const [paidUsersData, setPaidUserData] = useState([]);
  const [membershipUsersData, setMembershipUsersData] = useState([]);
  const [premiumUsersData, setPremiumUsersData] = useState([]);
  const [basicUsersData, setBasicUsersData] = useState([]);

  const [searchEmail, setSearchEmail] = useState("");

  const fetchAllUsersData = async () => {
    setLoading(true);
    await getAuthToken(currentUser);
    // get all user data
    const { data: backendUserData, error: backendDataError } =
      await getAllUsersBackEnd({ firebaseId: currentUser.uid, token: token });
    // get login timestamp for all users
    const { data: userTimeStamp, error: timeStampError } =
      await getAllUserLoginTime({ firebaseId: currentUser.uid, token: token });

    if (!backendDataError && !timeStampError) {
      let mergedAllUsers = [];
      let mergedPaidUsers = [];
      let membershipUsers = [];
      let premiumUsers = [];
      let basicUsers = [];
      // merging all users data with their respective login timestamps
      backendUserData.all_users_data.forEach((data, index) => {
        mergedAllUsers.push({
          ...data,
          loginTimeStamp: userTimeStamp.timestamp[data.firebase_id],
        });
        if (!data.coaching || data.coaching?.active === false) {
          basicUsers.push({
            ...data,
            loginTimeStamp: userTimeStamp.timestamp[data.firebase_id],
          });
        }
      });
      // merging all paid users data with their respective login timestamps
      backendUserData.paid_users_data.forEach((data, index) => {
        mergedPaidUsers.push({
          ...data,
          loginTimeStamp: userTimeStamp.timestamp[data.firebase_id],
        });
        if (data.coaching?.plan === "premium") {
          premiumUsers.push({
            ...data,
            loginTimeStamp: userTimeStamp.timestamp[data.firebase_id],
          });
        }
        if (data.coaching?.plan === "membership") {
          membershipUsers.push({
            ...data,
            loginTimeStamp: userTimeStamp.timestamp[data.firebase_id],
          });
        }
      });

      // console.log(backendUserData);

      setPaidUserData(mergedPaidUsers);
      setMembershipUsersData(membershipUsers);
      setPremiumUsersData(premiumUsers);
      setBasicUsersData(basicUsers);
      setAllUsersData(mergedAllUsers);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsersData();
  }, []);

  const searchByEmail = (
    <Input
      placeholder="Search Email"
      value={searchEmail}
      onChange={(e) => {
        const email = e.target.value;
        setSearchEmail(email);
      }}
    />
  );

  const filterByNameEmail = (data) => {
    const filteredData = data.filter((user) => {
      let patientName = user.firstName + " " + user.lastName;
      if (user?.email?.toLowerCase()?.includes(searchEmail.toLowerCase()))
        return user;
      else if (patientName?.toLowerCase()?.includes(searchEmail.toLowerCase()))
        return user;
    });
    return filteredData;
  };

  const deleteUser = (firebaseId) => {
    axios({
      method: "delete",
      url: process.env.REACT_APP_BACKEND_URL + "user",
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    })
      .then(() => {
        const filteredAllData = allUsersData.filter(
          (user) => !user?.firebase_id?.includes(firebaseId)
        );
        const filteredPaidData = paidUsersData.filter(
          (user) => !user?.firebase_id?.includes(firebaseId)
        );
        setAllUsersData(filteredAllData);
        setPaidUserData(filteredPaidData);
      })
      .catch((err) => console.log("error while deleting user: ", err));
  };

  const handleChangeProgramType = (patientID, progType) => {
    let data = {
      coaching: {
        active: true,
        start: moment().format("MM/DD/YYYY"),
      },
    };
    if (progType === "dac" || progType === "ccfm")
      data.coaching.plan = "premium";

    if (progType === "rapilot") data.coaching.plan = "membership";

    db.collection("users")
      .doc(patientID)
      .update({ [progType]: true });

    let config = {
      method: "put",
      url: process.env.REACT_APP_BACKEND_URL + "users",
      headers: {
        Authorization: "JWT " + token,
        x_firebase_id: patientID,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(() => {
        fetchAllUsersData();
      })
      .catch((err) => console.log("error while updating user: ", err));
  };

  // to filter out the columns according to the super users
  const filterColumns = (columns) => {
    // remove delete icon is not super user
    if (!superUsers.includes(currentUser.uid))
      return columns.filter((col) => {
        return col.key !== "delete";
      });
    else return columns;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Basic Users" key="1">
        <Table
          bordered
          columns={filterColumns(
            paidUsersColumn(searchByEmail, deleteUser, handleChangeProgramType)
          )}
          dataSource={filterByNameEmail(basicUsersData)}
          scroll={{ x: "100%" }}
        />
      </TabPane>
      <TabPane tab="Paid Users" key="2">
        <Table
          bordered
          columns={filterColumns(
            allUsersColumn(searchByEmail, deleteUser, handleChangeProgramType)
          )}
          dataSource={filterByNameEmail(paidUsersData)}
          scroll={{ x: "100%" }}
        />
      </TabPane>
      <TabPane tab="Membership Users" key="3">
        <Table
          bordered
          columns={filterColumns(
            allUsersColumn(searchByEmail, deleteUser, handleChangeProgramType)
          )}
          dataSource={filterByNameEmail(membershipUsersData)}
          scroll={{ x: "100%" }}
        />
      </TabPane>
      <TabPane tab="All Access Users" key="4">
        <Table
          bordered
          columns={filterColumns(
            allUsersColumn(searchByEmail, deleteUser, handleChangeProgramType)
          )}
          dataSource={filterByNameEmail(premiumUsersData)}
          scroll={{ x: "100%" }}
        />
      </TabPane>
      <TabPane tab="All Users" key="5">
        <Table
          bordered
          columns={filterColumns(
            paidUsersColumn(searchByEmail, deleteUser, handleChangeProgramType)
          )}
          dataSource={filterByNameEmail(allUsersData)}
          scroll={{ x: "100%" }}
        />
      </TabPane>
    </Tabs>
  );
}
