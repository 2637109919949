import React, {useEffect, useState} from 'react'
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import { getAllEvents } from '../../api/users';
import {
  Button,
  Tabs,
  Table,
  Tag,
  Space,
  Row,
  Col
} from 'antd';
import { eventsColumn } from '../../components/Reports/reportColumn';
import Loading from '../../components/Loading';
import axios from 'axios';

export default function Financials() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [reportedData, setReportedData] = useState([]);

  useEffect(() => {
    async function fetchAllReports() {
      if(!token)
        await getAuthToken(currentUser);
      getReportList()
    }
    fetchAllReports()
  }, [])

  const getReportList = () => {
    setLoading(true);
    let config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + 'patient/report',
      headers: {x_firebase_id: currentUser.uid, Authorization: 'JWT ' + token}
    }
    axios(config)
    .then(({data}) => {
        console.log("TESTING: ", data)
        setReportedData(data)
        setLoading(false)
    }).catch(err => {
        setLoading(false)
        console.log('error when fetching reported post list')
    })
  }

  const deletePost = (postData) => {
		let config = {
			method: 'delete',
			url: process.env.REACT_APP_BACKEND_URL + 'post',
			headers: {
					x_firebase_id: currentUser.uid, 
					Authorization: 'JWT ' + token,
					post_id: postData.post_id,
					report_id: postData.report_id,
					type: 'post'
				}
			}
		if(postData.comment_id) {
			config.headers.type = 'comment'
			config.headers.comment_id = postData.comment_id
		}
		console.log(config)
		axios(config)
    .then(() => {
			getReportList()
		}).catch(err => {
        console.log('error when deleting post')
    })
  }

  if (loading) {
    return (
      <Loading/>
    )
  }

  return (
    <div>
      <Table columns={eventsColumn(deletePost)} dataSource={reportedData} bordered />
    </div>
  )
}
