import React, { useEffect, useState } from "react";
import { Button, Tabs, Table, Tag, Space, Row, Col, Input } from "antd";
import moment from "moment";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import {
  ReferenceLine,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  allUsersColumn,
  paidUsersColumn,
} from "../../components/Analytics/columns";
import { getAllUsersBackEnd, getAllUserLoginTime } from "../../api/users";
import StatusCard from "../../components/Analytics/StatusCard";
import Loading from "../../components/Loading";
import axios from "axios";
import { db, superUsers } from "../../services/firebase";
import AnalyticsTable from "./AnalyticsTable";

const { TabPane } = Tabs;

export default function Analytics() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [allUsersData, setAllUsersData] = useState([]);
  const [paidUsersData, setPaidUserData] = useState([]);
  const [dailyActiveUsers, setDailyActiveUsers] = useState(0);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState(0);
  const [analyticsTableData, setAnalyticsTableData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [chartTimeline, setChartTimeline] = useState({
    label: "Daily",
    value: "daily",
  });
  const [appointmentAnalytics, setAppointmentAnalytics] = useState({
    weeklyAppointments: [],
    monthlyAppointments: [],
  });
  const [overAllData, setOverAllData] = useState({
    daily_no_of_new_subscribers: 0,
    daily_no_of_new_users: 0,
    monthly_no_of_new_subscribers: 0,
    monthly_no_of_new_users: 0,
    no_of_coaching: 0,
    no_of_subscribers: 0,
    daily_active_users: 0,
    monthly_active_users: 0,
    previous_week_no_of_new_users: 0,
    previous_2_week_no_of_new_users: 0,
  });
  const [searchEmail, setSearchEmail] = useState("");

  const fetchAllUsersData = async () => {
    setLoading(true);
    if(!token) await getAuthToken(currentUser);
    getAppointmentAnalytics();
    // get all user data
    const { data: backendUserData, error: backendDataError } =
      await getAllUsersBackEnd({ firebaseId: currentUser.uid, token: token });
    // get login timestamp for all users
    const { data: userTimeStamp, error: timeStampError } =
      await getAllUserLoginTime({ firebaseId: currentUser.uid, token: token });

    if (!backendDataError && !timeStampError) {
      let mergedAllUsers = [];
      let mergedPaidUsers = [];
      // merging all users data with their respective login timestamps
      backendUserData.all_users_data.forEach((data, index) => {
        mergedAllUsers.push({
          ...data,
          loginTimeStamp: userTimeStamp.timestamp[data.firebase_id],
        });
      });
      // merging all paid users data with their respective login timestamps
      backendUserData.paid_users_data.forEach((data, index) => {
        mergedPaidUsers.push({
          ...data,
          loginTimeStamp: userTimeStamp.timestamp[data.firebase_id],
        });
      });

      console.log(backendUserData);

      setAnalyticsTableData(backendUserData);
      setOverAllData(backendUserData.over_all);
      setPaidUserData(mergedPaidUsers);
      setAllUsersData(mergedAllUsers);
      prepareChartData(backendUserData.progress);
    }
  };

  useEffect(() => {
    fetchAllUsersData();
  }, []);

  const getAppointmentAnalytics = () => {
    let config = {
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "admin/portal/appointment",
      headers: {
        Authorization: "JWT " + token,
        x_firebase_id: currentUser.uid,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(({ data }) => {
        setAppointmentAnalytics(data);
      })
      .catch((err) =>
        console.log("error while getting appointment analytics: ", err)
      );
  };

  const prepareChartData = (data) => {
    if (!data || data.length === 0) {
      setLoading(false);
      return;
    }

    let chartData = [];
    data.forEach((each) => {
      chartData.unshift({
        newUsers: each.daily_no_of_new_users,
        activeUsers: each.daily_active_users,
        subscribers: each.daily_no_of_new_subscribers,
        monthyl: each.monthly_no_of_new_users,
        date: moment(each.created_at).format("D-MMM"),
        fullDate: each.created_at,
        totalUsers: each.total_no_of_users || 0,
      });
    });

    console.log("cahrt data is", chartData);

    setChartData(chartData);
    setLoading(false);
  };

  const searchByEmail = (
    <Input
      placeholder="Search Email"
      value={searchEmail}
      onChange={(e) => {
        const email = e.target.value;
        setSearchEmail(email);
      }}
    />
  );

  const filterByNameEmail = (data) => {
    const filteredData = data.filter((user) => {
      let patientName = user.firstName + " " + user.lastName;
      if (user?.email?.toLowerCase()?.includes(searchEmail.toLowerCase()))
        return user;
      else if (patientName?.toLowerCase()?.includes(searchEmail.toLowerCase()))
        return user;
    });
    return filteredData;
  };

  const deleteUser = (firebaseId) => {
    console.log("clicked: ", firebaseId);
    axios({
      method: "delete",
      url: process.env.REACT_APP_BACKEND_URL + "user",
      headers: { x_firebase_id: firebaseId, Authorization: "JWT " + token },
    })
      .then(() => {
        const filteredAllData = allUsersData.filter(
          (user) => !user?.firebase_id?.includes(firebaseId)
        );
        const filteredPaidData = paidUsersData.filter(
          (user) => !user?.firebase_id?.includes(firebaseId)
        );
        setAllUsersData(filteredAllData);
        setPaidUserData(filteredPaidData);
      })
      .catch((err) => console.log("error while deleting user: ", err));
  };

  const handleChangeProgramType = (patientID, progType) => {
    console.log("clicked: ", patientID, progType);
    let data = {
      coaching: {
        active: true,
        start: moment().format("MM/DD/YYYY"),
      },
    };
    if (progType === "dac" || progType === "ccfm")
      data.coaching.plan = "premium";

    if (progType === "rapilot") data.coaching.plan = "membership";

    db.collection("users")
      .doc(patientID)
      .update({ [progType]: true });

    let config = {
      method: "put",
      url: process.env.REACT_APP_BACKEND_URL + "users",
      headers: {
        Authorization: "JWT " + token,
        x_firebase_id: patientID,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(() => {
        fetchAllUsersData();
      })
      .catch((err) => console.log("error while updating user: ", err));
  };

  // to filter out the columns according to the super users
  const filterColumns = (columns) => {
    // remove delete icon is not super user
    if (!superUsers.includes(currentUser.uid))
      return columns.filter((col) => {
        return col.key !== "delete";
      });
    else return columns;
  };

  if (loading) {
    return <Loading />;
  }

  function gcd(a, b) {
    if (b > a) {
      let temp = a;
      a = b;
      b = temp;
    }
    while (b != 0) {
      let m = a % b;
      a = b;
      b = m;
    }
    return a;
  }

  /* ratio is to get the gcd and divide each component by the gcd, then return a string with the typical colon-separated value */
  function ratio(x, y) {
    let c = gcd(x, y);
    return "" + x / c + ":" + y / c;
  }

  return (
    <>
      <Row
        style={{ marginTop: 20, marginBottom: 20, justifyContent: "center" }}
      >
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Daily Active Users"}
            content={overAllData.daily_active_users}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Weekly Active Users"}
            content={overAllData.rolling_active_week}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Monthly Active Users"}
            content={overAllData.rolling_active_month}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Monthly Conversion"}
            content={`${Math.round(
              (overAllData.monthly_no_of_new_subscribers /
                (overAllData.monthly_no_of_new_users || 1)) *
                100
            )}%`}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 20, justifyContent: "center" }}>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Monthly New Paid Users"}
            content={overAllData.monthly_no_of_new_subscribers}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Daily New Users"}
            content={overAllData.daily_no_of_new_users}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Monthly New Users"}
            content={overAllData.monthly_no_of_new_users}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Total No Paid Users"}
            content={overAllData.no_of_subscribers}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Weekly Retention Rate"}
            content={`${Math.round(
              (overAllData.rolling_active_week /
                (overAllData.previous_week_active_users +
                  overAllData.rolling_active_week || 1)) *
                100
            )}%`}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Monthly Retention Rate"}
            content={`${Math.round(
              (overAllData.rolling_active_month /
                (overAllData.previous_month_active_users +
                  overAllData.rolling_active_month || 1)) *
                100
            )}%`}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Churn Rate"}
            content={`${Math.round(
              100 -
                (overAllData.rolling_active_month /
                  (overAllData.previous_month_active_users +
                    overAllData.rolling_active_month || 1)) *
                  100
            )}%`}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Stickiness"}
            content={ratio(
              overAllData.daily_active_users || 1,
              overAllData.monthly_active_users || 1
            )}
            // content={Math.round(
            //   overAllData.daily_active_users /
            //     (overAllData.monthly_active_users || 1)
            // )}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Time To Purchase"}
            content={`${Math.round(
              overAllData.time_to_purchase / (overAllData.no_of_coaching || 1)
            )} Days`}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Education Content Access"}
            content={`${overAllData.education_content_engagement?.length} / Month`}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Membership Plan Conversion"}
            content={`${Math.round(
              (overAllData.membership_no / (overAllData.no_of_coaching || 1)) *
                100
            )}%`}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"All Access Plan Conversion"}
            content={`${Math.round(
              (overAllData.premium_no / (overAllData.no_of_coaching || 1)) * 100
            )}%`}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Prior Week Completed \n Appointment"}
            content={appointmentAnalytics?.weeklyFinishedAppointments?.length}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Weekly Upcoming \n Appointment"}
            content={appointmentAnalytics?.weeklyBookedAppointments?.length}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Prior Month Completed \n Appointment"}
            content={appointmentAnalytics?.monthlyFinishedAppointments?.length}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Monthly Upcoming \n Appointment"}
            content={appointmentAnalytics?.monthlyBookedAppointments?.length}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Total Basic User Appointments"}
            content={appointmentAnalytics?.basicBookedAppointments}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Total Premium User Appointments"}
            content={appointmentAnalytics?.paidBookedAppointments}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Total No Coaching Users"}
            content={overAllData.no_of_coaching}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Total No of Users"}
            content={allUsersData.length}
          />
        </Col>
      </Row>

      <div
        style={{
          marginTop: 40,
          marginBottom: 40,
          padding: 40,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            marginBottom: 20,
          }}
        >
          <div style={{ width: "50%" }}>
            <Select
              isMulti={true}
              placeholder={"Select metric..."}
              options={[
                { label: "New Users", value: "newUsers" },
                { label: "Daily Active Users", value: "activeUsers" },
                { label: "New Paid Users", value: "subscribers" },
                { label: "Total Users", value: "totalUsers" },
              ]}
              value={selectedOptions}
              onChange={(val) => setSelectedOptions(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#1890ff33",
                  primary: "#1890ff",
                },
              })}
            />
          </div>

          <div style={{ width: 200 }}>
            <Select
              isMulti={false}
              placeholder={"Select timeline..."}
              options={[
                { label: "Daily", value: "daily" },
                { label: "Weekly", value: "weekly" },
                { label: "Monthly", value: "monthly" },
              ]}
              value={chartTimeline}
              onChange={(val) => setChartTimeline(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#1890ff33",
                  primary: "#1890ff",
                },
              })}
            />
          </div>
        </div>
        <Chart
          data={chartData}
          selectedOptions={selectedOptions}
          timeline={chartTimeline}
        />
      </div>
      <AnalyticsTable data={analyticsTableData} />

      {/* <Tabs defaultActiveKey="1">
        <TabPane tab="Paid Users" key="1">
          <Table
            bordered
            columns={filterColumns(
              allUsersColumn(searchByEmail, deleteUser, handleChangeProgramType)
            )}
            dataSource={filterByNameEmail(paidUsersData)}
            scroll={{ x: "100%" }}
          />
        </TabPane>
        <TabPane tab="All Users" key="3">
          <Table
            bordered
            columns={filterColumns(
              paidUsersColumn(
                searchByEmail,
                deleteUser,
                handleChangeProgramType
              )
            )}
            dataSource={filterByNameEmail(allUsersData)}
            scroll={{ x: "100%" }}
          />
        </TabPane>
      </Tabs> */}
    </>
  );
}

const Chart = ({ data, selectedOptions, timeline }) => {
  const [chartData, setChartData] = useState([]);
  let width = window.innerWidth / 1.5;

  const random_hex_color_code = (i) => {
    switch (i.value) {
      case "activeUsers":
        return "green";
      case "newUsers":
        return "red";
      case "totalUsers":
        return "black";
      default:
        return "blue";
    }
  };

  useEffect(() => {
    if (!data || data.length === 0) return;

    console.log("timeline is", timeline);

    switch (timeline.value) {
      case "daily":
        console.log("its daily");
        setChartData(data);
        break;
      case "weekly":
        console.log("its weekly");
        setChartData(getWeeklyData(data));
        break;
      case "monthly":
        console.log("its monhtly");
        setChartData(getMonthlyData(data));
        break;
      default:
        setChartData(data);
    }
  }, [data, timeline]);

  const getWeeklyData = (data) => {
    console.log("getting weekly data");
    let finalObject = {};
    for (let i = data.length - 1; i >= 0; i--) {
      let entry = data[i];
      let date = moment.utc(entry.fullDate);
      let startOfWeek = moment.utc(entry.fullDate).startOf("week");
      let endOfWeek = moment.utc(entry.fullDate).endOf("week");

      //it falls within the week from the corresponding sunday, add it
      let temp = finalObject[startOfWeek.format("D-MMM")] || {
        newUsers: 0,
        subscribers: 0,
        totalUsers: 0,
      };
      temp["newUsers"] += entry.newUsers;
      temp["subscribers"] += entry.subscribers;
      temp["totalUsers"] += entry.totalUsers;
      finalObject[startOfWeek.format("D-MMM")] = temp;
    }

    let toReturn = [];
    if (!finalObject || Object.keys(finalObject).length === 0) return [];

    Object.keys(finalObject).forEach((date, index) => {
      let temp = finalObject[date];
      temp.date = date;
      toReturn.unshift(temp);
    });

    for (let i = toReturn.length - 1; i >= 1; i--) {
      let thisWeek = toReturn[i].newUsers || 0;
      let lastWeek = toReturn[i - 1].newUsers || 0;
      if (lastWeek && lastWeek > 0) {
        toReturn[i].change = `${Math.round(
          ((thisWeek - lastWeek) / lastWeek) * 100
        )}%`;
      } else {
        toReturn[i].change = `${thisWeek * 100}%`;
      }
    }
    return toReturn;
  };

  const getMonthlyData = (data) => {
    console.log("getting monthly data");
    let finalObject = {};
    let currentMonth = moment.utc().startOf("month");
    let index = 1;
    for (let i = data.length - 1; i >= 0; i--) {
      let entry = data[i];
      let date = moment.utc(entry.fullDate);
      if (date.isSame(currentMonth, "month")) {
        //it falls within the same month
        let temp = finalObject[currentMonth.format("D-MMM")] || {
          newUsers: 0,
          subscribers: 0,
          totalUsers: 0,
        };
        temp["newUsers"] += entry.newUsers;
        temp["subscribers"] += entry.subscribers;
        temp["totalUsers"] += entry.totalUsers;
        finalObject[currentMonth.format("D-MMM")] = temp;
      } else {
        currentMonth = currentMonth.subtract(1, "month");
        let temp = finalObject[currentMonth.format("D-MMM")] || {
          newUsers: 0,
          subscribers: 0,
          totalUsers: 0,
        };
        temp["newUsers"] += entry.newUsers;
        temp["subscribers"] += entry.subscribers;
        temp["totalUsers"] += entry.totalUsers;

        finalObject[currentMonth.format("D-MMM")] = temp;
      }
    }

    let toReturn = [];
    if (!finalObject || Object.keys(finalObject).length === 0) return [];

    Object.keys(finalObject).forEach((date) => {
      let temp = finalObject[date];
      temp.date = date;
      toReturn.unshift(temp);
    });

    for (let i = toReturn.length - 1; i >= 1; i--) {
      let thisMonth = toReturn[i].newUsers || 0;
      let lastMonth = toReturn[i - 1].newUsers || 0;
      if (lastMonth && lastMonth > 0) {
        toReturn[i].change = `${Math.round(
          ((thisMonth - lastMonth) / lastMonth) * 100
        )}%`;
      } else {
        toReturn[i].change = `${thisMonth * 100}%`;
      }
    }

    console.log("finalobject", finalObject);
    return toReturn;
  };

  const getLines = () => {
    let lines =
      selectedOptions &&
      selectedOptions.map((x, i) => (
        <Line
          key={x.label}
          dot={false}
          name={x.label}
          type="monotone"
          dataKey={x.value}
          stroke={random_hex_color_code(x)}
        />
      ));
    return lines;
  };

  return (
    <LineChart
      width={width}
      height={300}
      data={chartData}
      margin={{ top: 5, right: 40, bottom: 5, left: 0 }}
    >
      <XAxis padding={{ top: 20 }} dataKey="date" type={"category"} />
      <YAxis />
      {getLines()}
      {selectedOptions &&
      selectedOptions.length > 0 &&
      (timeline.value === "weekly" || timeline.value === "monthly") ? (
        <Tooltip
          content={({ active, payload, label }) => {
            let date = moment(label).format("D-MMM");
            return (
              <div style={{ padding: "10px 20px", backgroundColor: "#ffffdf" }}>
                <p>{date}</p>
                {payload && payload.length > 0 && payload[0].payload ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontWeight: "bold" }}>
                      New Users{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.newUsers}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      Paid Users{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.subscribers}
                      </span>
                    </p>                   
                    <p style={{ fontWeight: "bold" }}>
                      Total Users{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.totalUsers}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      {`${timeline.value === "monthly" ? "MoM" : "WoW"} Growth`}{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.change}
                      </span>
                    </p>
                  </div>
                ) : null}
              </div>
            );
          }}
        />
      ) : (
        <Tooltip />
      )}
      <Legend
        verticalAlign={"top"}
        align={"center"}
        height={40}
        wrapperStyle={{ left: 40 }}
      />
    </LineChart>
  );
};
