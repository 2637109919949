import React, {useEffect, useState} from 'react'
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import { getAllEvents } from '../../api/users';
import {
  Button,
  Tabs,
  Table,
  Tag,
  Space,
  Row,
  Col
} from 'antd';
import { eventsColumn } from '../../components/Events/eventsColumn';
import Loading from '../../components/Loading';
import axios from 'axios';

export default function Events() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState([]);

  console.log(eventData)
  useEffect(() => {
    async function fetchAllEvents() {
      setLoading(true);
      await getAuthToken(currentUser);
      // get all events data
      const {data: eventData, error: eventError} = await getAllEvents({x_firebase_id: currentUser.uid, token: token})
      setLoading(false)
      if(!eventError) {
        let circleArray = [...eventData?.recurringCircles, ...eventData?.circlesHosted, ...eventData?.closedCircles, ...eventData?.openCircles].sort((first, second) => {
          return moment(first.scheduled_date).diff(moment(second.scheduled_date))
        })
        setEventData(circleArray || [])
        getCommunityCount(circleArray)
      }
    }
    fetchAllEvents()
  }, [])

  const getCommunityCount = (eventList) => {
    let config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + 'communities',
      headers: {x_firebase_id: currentUser.uid, Authorization: 'JWT ' + token}
    }
    axios(config)
    .then(({data}) => {
      let communities = data.userCommunities.concat(data.otherCommunities)
      let tempEventData = []
      eventList.forEach(circle => {
        let communityCircle = communities.find(comm => comm._id === circle.community_id)
        let commCount = communityCircle ? Object.keys(communityCircle.users).length : 0
        tempEventData.push({
          ...circle,
          communityCount: commCount
        })
      })
      setEventData(tempEventData)
    }).catch(err => console.log('error when fetching community'))
  }


  if (loading) {
    return (
      <Loading/>
    )
  }

  return (
    <div>
      <Table columns={eventsColumn} dataSource={eventData} bordered />
    </div>
  )
}
