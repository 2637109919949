import { Tag, Select } from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

export const allColumns = () => {
  return [
    {
      title: "Metrics",
      dataIndex: "title",
      key: "title",
      width: 130,
      render: (title) => {
        return title && <p>{title}</p>;
      },
    },
    {
      title: "Value",
      width: 130,
      dataIndex: "val",
      key: "val",
      render: (val) => {
        return val && <p>{val}</p>;
      },
    },
  ];
};
