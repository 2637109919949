import React from 'react'
import { Card } from 'antd';

export default function StatusCard({cardTitle, content}) {
  return (
    <Card title={cardTitle} headStyle={{textAlign:'center'}} bordered={true} style={{ width: 270 }}>
      <h1 style={{fontSize: 30, textAlign:'center', wordWrap: 'normal'}}>{content}</h1>
    </Card>
  )
}
