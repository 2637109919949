import moment from  'moment';
import { Link, NavLink } from "react-router-dom";

export const appointmentListColumn = (allProvidersData) => {
    return [
        {
          title: 'Provider Name',
          dataIndex: 'providerData',
          key: '1',
          render: (providerData) => {
            return (<p>{ providerData?.first_name + ' ' + providerData?.last_name + ` (${providerData?.user_type})` }</p>)
          },
          filters: allProvidersData,
          onFilter: (value, record) => record?.providerData?.firebase_id === (value),
        },
        {
          title: 'Patient Name',
          dataIndex: 'patientData',
          key: '3',
          render: (patientData) => {
            return (<p>{ patientData?.name + ` (${patientData?.planType})` }</p>)
          }
        },
        {
          title: 'Scheduled Time',
          dataIndex: 'appointmentStart',
          key: '4',
          sorter: (a, b) => a.appointmentStart - b.appointmentStart,
          render: (appointmentStart) => {
            return (<p>{moment(appointmentStart).format('lll')}</p>)
          },
        },
        {
          title: 'Created At',
          dataIndex: 'appointmentCreatedAt',
          key: '4',
          render: (appointmentCreatedAt) => {
            return (<p>{moment(appointmentCreatedAt).format('lll')}</p>)
          }
        },
        {
          title: 'Payment',
          dataIndex: 'appointmentPayment',
          key: '5',
          render: (appointmentPayment) => {
            return (<p>{(appointmentPayment === 'free' ? appointmentPayment : `$${appointmentPayment}`)}</p>)
          }
        },
      ]
}
