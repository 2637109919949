import React, {useEffect, useState} from 'react'
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import Select from "react-select";
import { getAllEvents } from '../../api/users';
import {
  Button,
  Tabs,
  Table,
  Tag,
  Space,
  Row,
  Col
} from 'antd';
import { eventsColumn } from '../../components/Financials/financialColumn';
import Loading from '../../components/Loading';
import axios from 'axios';
import {
  ReferenceLine,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function Financials() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [financialData, setFinancialData] = useState([]);
  // console.log(financialData)
  const [finCategory, setFinCategory] = useState([
    { label: "Total Revenue", value: "total" },
    { label: "PC Revenue", value: "pc_inc" },
    { label: "Inc Revenue", value: "aila_inc" },
    // { label: "Projected Revenue for Upcoming Appointments", value: "projected_appointment" },
  ]);

  useEffect(() => {
    async function fetchAllPayments() {
      if(!token)
        await getAuthToken(currentUser);
      getPaymentList()
    }
    fetchAllPayments()
  }, [])

  const getPaymentList = async () => {
    setLoading(true);
    let config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + 'stripe/payment-list',
      headers: {x_firebase_id: currentUser.uid, Authorization: 'JWT ' + token}
    }
    axios(config)
    .then(({data}) => {
        // console.log("TESTING: ", groupBy(data))
        let tempArr = groupBy(data)
        tempArr.sort((a,b)=>new Date(a.date) - new Date(b.date));
        setFinancialData(tempArr)
        getAppointmentList(tempArr)
        setLoading(false)
    }).catch(err => {
        setLoading(false)
        console.log('error when fetching payment list', err)
    })
  }

  const clubArray = (arr) => {
    return arr.reduce((acc, val, ind) => {
       const index = acc.findIndex(el => el.date === val.date);
       if(index !== -1){
          const key = Object.keys(val)[1];
          acc[index][key] = val[key];
       } else {
          acc.push(val);
       };
       return acc;
    }, []);
 };

  const getAppointmentList = (tempArr) => {
    setLoading(true);
    let config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + 'admin/appointments',
      headers: {x_firebase_id: currentUser.uid, Authorization: 'JWT ' + token}
    }
    axios(config)
    .then(({data}) => {
      // console.log('testing:', data)
      let projectedData = groupByProjectedAppointment(data)
      projectedData.sort((a,b)=>new Date(a.date) - new Date(b.date));
      setFinancialData(clubArray([...tempArr, ...projectedData]))
      setLoading(false);
    }).catch(err => {
        console.log('error when fetching appointment list')
        setLoading(false);
    })
  }
  
  const groupByProjectedAppointment = (elements) => {
    const formatted = elements.filter(elem => moment.unix(elem.appointmentStart/1000).isAfter(moment())).map(elem => {
      return { appointmentStart: moment.unix(elem.appointmentStart/1000).startOf('month').format('YYYY-MM-DD'), projected_appointment: 0, data: elem }
    })
    // console.log('formated dates: ', formatted)
    const dates = formatted.map(elem => elem.appointmentStart)
    // console.log('dates: ', dates)
    const uniqueDates = dates.filter((date, index) => {
      if(moment(date).isSameOrAfter(moment().startOf('M'))) {
        return (dates.indexOf(date) === index)
      }
    })
    // console.log('unique dates: ', uniqueDates)
    return uniqueDates.map(date => {

      const projected_appointment = formatted.filter(elem => elem.appointmentStart === date).reduce((projected_appointment, elem) => {
        return projected_appointment + (Number(elem.data?.appointmentPayment) || 0)
      }, 0)

      let pro_rev_of_appointments = { 
        date, 
        projected_appointment: projected_appointment.toFixed(2)
      }
      // console.log('testing: ', pro_rev_of_appointments)
      return pro_rev_of_appointments
    })
  }

  const groupBy = (elements) => {
    const formatted = elements.map(elem => {
      return { created: moment.unix(elem.created).startOf('month').format('YYYY-MM-DD'), total: 0, data: elem }
    })
    // console.log('formated dates: ', formatted)
    const dates = formatted.map(elem => elem.created)
    // console.log('dates: ', dates)
    const uniqueDates = dates.filter((date, index) => dates.indexOf(date) === index)
    // console.log('unique dates: ', uniqueDates)
    return uniqueDates.map(date => {

      const total = formatted.filter(elem => elem.created === date && !elem.data?.refunded).reduce((total, elem) => total + elem.data.payment_received, 0)
      const estimated_total = formatted.filter(elem => elem.created === date && elem?.data?.estimate_payment_received).reduce((total, elem) => total + elem?.data?.estimate_payment_received, 0)
      const pc_inc = formatted.filter(elem => 
        elem.created === date && 
        elem.data.transfer_data?.destination  // transferred to PC inc if destination is present
      ).reduce((total, elem) => total + elem.data.payment_received, 0)
      const aila_inc = formatted.filter(elem => 
        elem.created === date && !elem.data?.refunded &&
        (!elem.data.transfer_data?.destination)
      ).reduce((total, elem) => total + elem.data.payment_received, 0)
      let allAccessCondn = ['all access', 'premium']
      const all_access = formatted.filter(elem => {
        return elem.created === date && !elem.data?.refunded &&
        allAccessCondn.some(el => elem.data.lines?.data?.[0]?.description?.toLowerCase()?.includes(el))
      }
      ).reduce((total, elem) => total + elem.data.payment_received, 0)
      let membershipCondn = ['membership']
      const membership = formatted.filter(elem => 
        elem.created === date && 
        membershipCondn.some(el => elem.data.lines?.data?.[0]?.description?.toLowerCase()?.includes(el) && elem.data.lines?.data?.[0]?.plan?.metadata?.payment !== 'ailapc')
      ).reduce((total, elem) => {
        // console.log('elem: ', elem, elem.data.lines?.data?.[0]?.description)
        return total + elem.data.payment_received
      }, 0)
      const appointment = formatted.filter(elem => 
        elem.created === date && 
        elem.data.metadata?.appointment_invoice &&
        !elem.data?.refunded
      ).reduce((total, elem) => {
        console.log('elem: ', elem)
        return total + elem.data.payment_received
      }, 0)

      let count_of_appointments = { 
        date, 
        total: total.toFixed(2), 
        aila_inc: aila_inc.toFixed(2), 
        pc_inc: pc_inc.toFixed(2), 
        all_access: all_access.toFixed(2), 
        membership: membership.toFixed(2), 
        appointment: appointment.toFixed(2),
        estimated_total: estimated_total.toFixed(2)
      }
      // console.log('testing: ', count_of_appointments)
      return count_of_appointments
    })
  }

  const random_hex_color_code = (i) => {
    return "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
  };

  const formatXAxis = (tickItem) => {
    let formatString = 'll'
    switch ('month') {
      case 'day':
        formatString = 'll'
        break;
      case 'week':
        formatString = `MMM, [wk ${Math.ceil(moment(tickItem).date() / 7)}], YY`
        break;
      case 'month':
        formatString = 'MMM, YY'
        break;
    
      default:
        break;
    }
    return moment(tickItem).format(formatString)
  }

  if (loading) {
    return (
      <Loading/>
    )
  }

  return (
    <div>
      <div
        style={{
          marginTop: 40,
          marginBottom: 40,
          padding: 40,
          // backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: window.innerWidth / 1.5 }}>
          <Select
            isMulti={true}
            isClearable={true}
            isSearchable={true}
            defaultValue={finCategory}
            placeholder={"Select category..."}
            options={[
              { label: "Total Revenue", value: "total" },
              { label: "PC Revenue", value: "pc_inc" },
              { label: "Inc Revenue", value: "aila_inc" },
              { label: "Estimated Claims", value: "estimated_total" },
              { label: "All Access Membership", value: "all_access" },
              { label: "Coaching Membership", value: "membership" },
              { label: "Appointment Revenue", value: "appointment" },
              { label: "Projected Revenue for Upcoming Appointments", value: "projected_appointment" }
            ]}
            value={finCategory}
            onChange={(val) => setFinCategory(val)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#1890ff33",
                primary: "#1890ff",
              },
            })}
          />
        </div>
      </div>
        <LineChart
          width={window.innerWidth / 1.5}
          height={300}
          data={financialData}
          margin={{ top: 5, right: 40, bottom: 5, left: 0 }}
        >
          {
            finCategory.map((rev, index) => (
              <Line
                key={rev.label}
                dot={false}
                name={rev.label}
                type="monotone"
                dataKey={rev.value}
                stroke={random_hex_color_code(index)}
              />
            ))
          }
          {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
          <XAxis padding={{ top: 20 }} dataKey="date" type={"category"} tickFormatter={formatXAxis} />
          <YAxis domain={[0, 8000]}/>
          <Tooltip
          // content={({ active, payload, label }) => {
          //   return (
          //     <div style={{ padding: "10px 20px", backgroundColor: "#ffffdf" }}>
          //       <p>{label}</p>
          //       {payload && payload.length > 0 && payload[0].payload ? (
          //         <div style={{ display: "flex", flexDirection: "column" }}>
          //           <p style={{ fontWeight: "bold" }}>
          //             Total Revenue:{" $"}
          //             <span style={{ fontWeight: "normal", marginLeft: 5 }}>
          //               {payload[0].payload.payment?.total || 0}
          //             </span>
          //           </p>
          //           <p style={{ fontWeight: "bold" }}>
          //             Inc Revenue:{" $"}
          //             <span style={{ fontWeight: "normal", marginLeft: 5 }}>
          //               {payload[0].payload.payment?.ailaInc || 0}
          //             </span>
          //           </p>                   
          //           <p style={{ fontWeight: "bold" }}>
          //             PC Revenue:{" $"}
          //             <span style={{ fontWeight: "normal", marginLeft: 5 }}>
          //               {payload[0].payload.payment?.ailaPC || 0}
          //             </span>
          //           </p>
          //         </div>
          //       ) : null}
          //     </div>
          //   );
          // }}
        />
        </LineChart>

      {/* <Table columns={eventsColumn} dataSource={financialData} bordered /> */}
    </div>
  )
}
