import moment from  'moment';
import { Link, NavLink } from "react-router-dom";

export const eventsColumn = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Scheduled Date',
    dataIndex: 'scheduled_date',
    key: 'scheduled_date',
    render: (date) => {
      return (<p>{moment(date).format('MMM Do YYYY, hh:mm A')}</p>)
    }
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    render: (duration) => {
      return (
      <p>
        {duration && (
              duration >= 60 ?
              `${Math.floor(duration/60)}h ${duration%60}m`
              : `${duration}m`
        )}
      </p>
      )
    }
  },
  {
    title: 'Recurring',
    dataIndex: 'recurring',
    key: 'recurring',
  },
  {
    title: 'Participants',
    dataIndex: 'communityCount',
    key: 'communityCount',
    render: (communityCount) => {
      return (
        <p>{communityCount}</p>
      )
    }
  },
  {
    title: 'Join',
    dataIndex: ['host_id'],
    key: 'recurring',
    render: (host_id, circle) => {
      return (
      <>
        <Link to={{pathname:'events/live-circle', circle: circle}}>Join</Link>
      </>
      )
    }
  },
];
