import React, { useEffect, useState } from "react";
import { Button, Tabs, Table, Tag, Space, Row, Col, Input } from "antd";
import moment from "moment";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import {
  ReferenceLine,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  allUsersColumn,
  paidUsersColumn,
  analyticsColumn,
} from "../../components/Analytics/columns";
import { getAllUsersBackEnd, getAllUserLoginTime } from "../../api/users";
import StatusCard from "../../components/Analytics/StatusCard";
import Loading from "../../components/Loading";
import axios from "axios";
import { db, superUsers } from "../../services/firebase";

const { TabPane } = Tabs;

export default function AnalyticsTable(props) {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);
  const [columns, setColumns] = useState([]);

  let tempArray = props?.data?.progress || [];

  const fetchColumnsData = () => {
    const groups =
      tempArray &&
      tempArray.reduce((groups, monthData) => {
        //   const created_at = monthData.created_at.toString().split("T")[0];
        const created_at = moment(monthData.created_at).format("MMMM 'YY");

        if (!groups[created_at]) {
          groups[created_at] = [];
        }
        groups[created_at].push(monthData);
        return groups;
      }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((created_at) => {
      return {
        created_at,
        monthData: groups[created_at],
      };
    });

    groupArrays && setMonthlyData(groupArrays);

    const columnData =
      groupArrays && groupArrays.map((month) => month.created_at);

    let tempColumns = [];
    columnData &&
      columnData.forEach((col) => {
        tempColumns.push({
          title: col,
          //   width: 300,
          dataIndex: col,
          key: col,
        });
      });

    setColumns(tempColumns);
  };
  useEffect(() => {
    fetchColumnsData();
  }, []);

  return (
    <>
      <Table
        bordered
        columns={analyticsColumn(monthlyData)}
        dataSource={monthlyData}
        scroll={{ x: "100%" }}
      />
    </>
  );
}
