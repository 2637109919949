import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Home from '../components/Layout/Home';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { useAuth } from '../context/AuthContext';
import Loading from '../components/Loading';
import { auth, validate } from '../services/firebase';

export default function Routes() {
  const { currentUser, loading, setLoading, setCurrentUser, getAuthToken } = useAuth();

  // Firebase OnAuth Listener async function
  async function onAuthStateChanged(user) {
    if (user && validate.includes(user.uid)) {
      setLoading(true);
      await setCurrentUser(user);
      await getAuthToken(user);
      setLoading(false);
    }
    // if (loadingRouter) setLoadingRouter(false);
    else {
        setLoading(false)
    }
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);
 
  return (
    <>
    {loading ? 
        <Loading/>
      :
      <Router>
        <Switch>
          <PublicRoute exact path="/">
            <Redirect to={{pathname: "/home"}} />
          </PublicRoute>
          <PublicRoute path="/login" exact>
            <Login/>
          </PublicRoute>
          <PrivateRoute path="/home" exact currentUser={currentUser}>
            <Home/>
          </PrivateRoute>
          <Route path="/" 
          //  component={Page404} 
          render={() => {
            return (
              currentUser ?
              <Redirect to="/home" /> :
              <Redirect to="/login" /> 
            )
          }}
          />
        </Switch>
      </Router>
    }
    </>
  )
}
