import React, { useEffect, useState } from "react";
import {
  Table,
  Form,
  Typography,
  Input,
  InputNumber,
  Popconfirm,
  Checkbox,
} from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Loading from "../../components/Loading";
import { db } from "../../services/firebase";
import moment from "moment";

const { TextArea } = Input;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <InputNumber /> : <TextArea rows={4} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function Referrals() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    async function fetchReferrals() {
      setLoading(true);

      const tempDoc = [];

      // get all provider referrals
      const provider_referrals = db.collection("provider_referrals");
      const snapshot = await provider_referrals.get();

      snapshot.forEach((doc) => {
        let temp = doc.data();

        tempDoc.push({
          key: doc.id,
          patientName: temp?.patient?.firstName + " " + temp?.patient?.lastName,
          patientDob: temp?.patient?.dob,
          patientEmail: temp?.patient?.email,
          patientState: temp?.patient?.state.label,
          patientInsurance: temp?.patient?.insurance,
          patientPayer: temp?.patient?.payer,
          patientPhone: temp?.patient?.phone,

          referringDate: moment(temp.created_at).format("L"),

          providerName:
            temp?.provider?.firstName + " " + temp?.provider?.lastName,
          providerEmail: temp?.provider?.email,
          providerOrganization: temp?.provider?.organization,
          providerPhone: temp?.provider?.phone,
          providerFax: temp?.provider?.fax,

          comments: temp?.patient?.comments,
          checkedIn: temp?.checkedIn,
          notes: temp?.notes ? temp?.notes : "",
        });
      });
      setLoading(false);
      setTableData(tempDoc || []);
      //   console.log("TABLE DATA:::", tempDoc);
    }

    fetchReferrals();
  }, []);

  //Update firestore collection
  const updateReferral = async (record) => {
    try {
      db.collection("provider_referrals").doc(record.key).update({
        checkedIn: record.checkedIn,
        notes: record.notes,
      });
    } catch (errInfo) {
      console.log("Update Failed:", errInfo);
    }
  };

  const isEditing = (record) => record.key === editingKey;

  // Edit notes field
  const edit = (record) => {
    form.setFieldsValue({
      notes: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const columns = [
    {
      title: "Checked In",
      dataIndex: "checkedIn",
      key: "checkin",
      width: 40,
      render: (checkedIn, record) => {
        return (
          <Checkbox
            defaultChecked={checkedIn}
            onChange={() => {
              handleCheckIn(checkedIn, record);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: "Referring Date",
      dataIndex: "referringDate",
      key: "rdate",
      width: 150,
    },
    {
      title: "Provider",
      children: [
        {
          title: "Referring Provider",
          dataIndex: "providerName",
          key: "pname",
          width: 200,
        },
        {
          title: "Provider Email",
          dataIndex: "providerEmail",
          key: "pemail",
          width: 250,
        },
        {
          title: "Provider Phone",
          dataIndex: "providerPhone",
          key: "phone",
          width: 150,
        },
        {
          title: "Provider Fax",
          dataIndex: "providerFax",
          key: "fax",
          width: 150,
        },
        {
          title: "Provider Organization",
          dataIndex: "providerOrganization",
          key: "organization",
          width: 150,
        },
      ],
    },
    {
      title: "Patient",
      children: [
        {
          title: "Patient Name",
          dataIndex: "patientName",
          key: "name",
          width: 200,
        },
        {
          title: "Patient Email",
          dataIndex: "patientEmail",
          key: "email",
          width: 250,
        },
        {
          title: "Patient Phone",
          dataIndex: "patientPhone",
          key: "patientPhone",
          width: 150,
        },
        {
          title: "Patient State",
          dataIndex: "patientState",
          key: "state",
          width: 150,
        },
        {
          title: "Comments",
          dataIndex: "comments",
          key: "comments",
          width: 150,
        },
        {
          title: "Patient Insurance",
          children: [
            {
              title: "Insurance Carrier",
              dataIndex: "patientPayer",
              key: "payer",
              width: 150,
            },
            {
              title: "Insurance ID",
              dataIndex: "patientInsurance",
              key: "insurance",
              width: 150,
            },
          ],
        },
      ],
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: 250,
      editable: true,
    },
    {
      title: "Edit",
      dataIndex: "operation",
      width: 100,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <CheckOutlined
              style={{ color: "green", fontSize: "large", marginRight: 10 }}
              onClick={() => {
                save(record.key);
              }}
            />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <CloseOutlined style={{ color: "red", fontSize: "large" }} />
            </Popconfirm>
          </span>
        ) : (
          <EditOutlined
            disabled={editingKey !== ""}
            style={{ fontSize: "large" }}
            onClick={() => {
              edit(record);
            }}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setTableData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setTableData(newData);
        setEditingKey("");
      }

      updateReferral(newData[index]);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleCheckIn = async (checkedIn, record) => {
    let temp = tableData;
    temp &&
      temp.forEach((referral) => {
        if (referral.key === record.key) {
          referral.checkedIn = !referral.checkedIn;
        }
      });

    setTableData(temp);
    updateReferral(record);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          bordered
          dataSource={tableData}
          scroll={{ x: "100%" }}
          pagination={{ pageSize: 8, onChange: cancel }}
        />
      </Form>
    </div>
  );
}
