import { Menu, Dropdown, Tag, Select, Button } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import moment from "moment";

const { Option, OptGroup } = Select;

export const coachUsersColumn = (
  handleEduProgChange,
  addCoach,
  addCommunity,
  delCommunity,
  removeCoach,
  searchByEmail, 
  handleStartProg
) => {
  return [
    {
      title: "Name",
      dataIndex: ["firstName", "lastName"],
      key: "name",
      width: 200,
      fixed: "left",
      render: (name, user) => {
        let reverseMsg =
          user.userMessages &&
          user.userMessages.messages &&
          user.userMessages.messages.reverse();
        let lastMsg =
          reverseMsg &&
          reverseMsg[0] &&
          reverseMsg[0].created_at &&
          moment().diff(moment(reverseMsg[0].created_at), "days");
        let lastAppoint =
          user.appointment &&
          user.appointment.latest_start_appointment_date &&
          moment().diff(
            moment.unix(
              user.appointment.latest_start_appointment_date / 1000,
              "MM/DD/YYYY"
            ),
            "days"
          );
        return (
          <>
            <p>
              {
                // if user last login over 5 days or last chat over 7 days, last appointment over 30 days show warning
                user &&
                  (user.lastSignedIn > 5 ||
                    lastMsg > 7 ||
                    lastAppoint > 30) && (
                    <>
                      <WarningTwoTone
                        name="User Warning"
                        twoToneColor="red"
                        title={`Last signed In > 5 days,\nLast Message > 7 days,\nLast appointment > 30 days`}
                        autoFocus
                      />{" "}
                    </>
                  )
              }
              {user.firstName} {user.lastName}
            </p>
          </>
        );
      },
    },
    {
      title: searchByEmail,
      dataIndex: "email",
      key: "email",
      width: 250,
      // fixed: 'left',
    },
    {
      title: "Coaching Start Date",
      dataIndex: "coaching",
      key: "coaching",
      render: (coaching) => (
        <p>{coaching ? moment(coaching.start).format("Do MMMM YYYY") : null}</p>
      ),
      width: 150,
    },
    {
      title: "Program Type",
      dataIndex: "coaching",
      key: "coaching",
      width: 150,
      render: (coaching, user) => {
        let userDeepLink = "";
        if (user.dac) userDeepLink = "Dac-Patient";
        if (user.rapilot) userDeepLink = "Ra-Patient";
        if (user.ccfm) userDeepLink = "CCFM";
        return <p>{userDeepLink}</p>;
      },
    },
    {
      title: "Membership Type",
      dataIndex: "coaching",
      key: "membershipType",
      width: 150,
      render: (coaching, user) => {
        return <p>{coaching?.plan}</p>;
      },
    },
    {
      title: 'Program Start',
      dataIndex: 'program_details',
      key: 'program_details',
      width: 150,
      align: 'center',
      render: (program_details, user) => {
        let programStart = program_details?.rapilot?.start_date
        let programWeek = programStart ? moment().diff(moment(programStart), 'weeks') : null
        let programType = ''
        if(user.dac)
          programType = 'dac'
        if(user.rapilot)
          programType = 'rapilot'
        if(user.ccfm)
          programType = 'ccfm'
        return (
          (programType === 'rapilot') &&
          <>
          { (!programStart) ? (
            <Button
              onClick={()=> handleStartProg(user, programType)}
            >
              Start
            </Button>
            ) : (
              <p>Week {programWeek + 1}</p>
            )
          }
          </>
        )
      }
    },
    {
      title: "Last Signed In",
      dataIndex: "loginTimeStamp",
      key: "lastSignedIn",
      width: 150,
      render: (loginTimeStamp) => {
        let latestLogin = loginTimeStamp?.timestamps?.length
          ? loginTimeStamp.timestamps.slice(-1)[0]
          : null;
        return (
          latestLogin && (
            <p>
              {moment().diff(latestLogin, "days")
                ? moment().diff(latestLogin, "days") + " days ago"
                : "Today"}
            </p>
          )
        );
      },
    },
    {
      title: 'Engagement Rate (% of days in last 30 days)',
      dataIndex: 'loginTimeStamp',
      key: 'loginTimeStamp',
      width: 150,
      render: (login, user) => {
        let rate
        let referenceDate = moment().subtract(29, 'days').format('MM-DD-YYYY')
        let uniqueDateArray = login && login.timestamps && login.timestamps.map(date => moment(date).format('MM-DD-YYYY'))
        let monthLogin = uniqueDateArray && uniqueDateArray.filter((data, index) => 
          moment(data).isSameOrAfter(referenceDate) 
          && moment(data).isSameOrAfter(moment(user.created_at).format('MM-DD-YYYY')) 
          && uniqueDateArray.indexOf(data) === index    // filter date to obtain past 30 days and remove duplicate dates
        )
        let dateDiff = moment().startOf('day').diff(moment(user.created_at).startOf('day'), "day") + 1;
        console.log(user.firebase_id, moment(), user.created_at, dateDiff, monthLogin)
        // if the created_at is less than 30 divide by diff
        if (dateDiff < 30)
          rate = monthLogin && (monthLogin.length / dateDiff) * 100;
        else rate = monthLogin && (monthLogin.length / 30) * 100;

        return login && login.timestamps && <p>{rate.toFixed(0)}%</p>;
      },
    },
    {
      title: "Last Message",
      dataIndex: "userMessages",
      key: "userMessages",
      width: 150,
      render: (msg) => {
        // Messages sorted by desc of created date so reverse and get the first element which is the latest msg sent ONLY BY PATIENT
        let reverseMsg = msg && msg.messages && msg.messages.reverse();
        // find returns the first element that satisfies the condition which is the latest message sent by PATIENT
        // let lastMsg = ( msg && msg.messages && reverseMsg.find(item => item.sender === 'Patient'))
        let lastMsg =
          reverseMsg &&
          reverseMsg[0] &&
          reverseMsg[0].created_at &&
          moment().diff(moment(reverseMsg[0].created_at), "days");
        return (
          // to display date as ago
          reverseMsg &&
          reverseMsg[0] && (
            <p>
              {" "}
              {reverseMsg && reverseMsg[0] && reverseMsg[0].sender === "Patient"
                ? "Patient"
                : "Coach"}{" "}
              sent {lastMsg ? lastMsg + " days ago" : "today"}
            </p>
          )
          // <p>{moment(lastMsg.created_at)}</p>
        );
      },
    },
    {
      title: () => {
        return (
          <p>
            Total Messages <br />
            (this month)
          </p>
        );
      },
      dataIndex: "userMessages",
      key: "userMessagesMonth",
      width: 150,
      render: (msg) => {
        // to display total number of messages sent ONLY BY PATIENT for the CURRENT MONTH
        let lastMsg =
          msg &&
          msg.messages &&
          msg.messages.filter(
            (item) =>
              item.sender === "Patient" &&
              moment().isSame(moment(item.created_at), "month")
          );
        return (
          lastMsg && (
            <>
              <p>Patient = {lastMsg.length}</p>
              <p>Coach = {msg.messages.length - lastMsg.length}</p>
            </>
          )
        );
      },
    },
    {
      title: "Last Appointment",
      dataIndex: "appointment",
      key: "appointment",
      width: 150,
      render: (appointment) => {
        if (appointment)
          // to display time as ago and it was in 13 digit unix timestamp have to divide by 1000
          return (
            <p>
              {moment
                .unix(appointment.latest_start_appointment_date / 1000)
                .format("MMM DD YYYY")}
            </p>
          );
      },
    },

    {
      title: "State",
      dataIndex: "patientState",
      key: "patientState",
      width: 150,
    },
    {
      title: "Assign Providers",
      dataIndex: "coachAssigned",
      key: "coachAssigned2",
      width: 200,
      render: (coachAssigned, user) => {
        let coachNamesTesting = coachAssigned?.doctors?.map(
          (doctor, index) => doctor.doctor_id
        );
        let coachOptionList = [];
        let sameState = user?.allCoachesData.filter((coach) =>
          coach?.user_type !== "Health Coach"
            ? coach?.licensed_states?.find(
                (states) => states.state === user.patientState
              )
            : coach
        );
        // grouping the providers according to user_type
        let allCoachData = sameState.reduce((data, doc) => {
          data[doc.user_type || "Other"] = data[doc.user_type || "Other"] || [];
          data[doc.user_type || "Other"].push(doc);
          return data;
        }, Object.create(null));

        Object.entries(allCoachData).forEach(([userType, doctorArray]) => {
          coachOptionList.push(
            <OptGroup label={userType}>
              {doctorArray.map((doc) => (
                <Option key={doc.firebase_id}>
                  {doc?.first_name} {doc?.last_name}
                </Option>
              ))}
            </OptGroup>
          );
        });

        return (
          <Select
            mode="multiple"
            allowClear={false}
            style={{ width: "100%" }}
            placeholder="Select provider"
            value={coachNamesTesting}
            // onChange={(value) => addCoach(value, user.firebase_id)}
            onSelect={(value) => addCoach(value, user.firebase_id)}
            onDeselect={(value) => removeCoach(value, user.firebase_id)}
          >
            {coachOptionList.reverse()}
          </Select>
        );
      },
    },
    {
      title: "Education Program",
      dataIndex: "coaching",
      key: "eduProg",
      width: 200,
      render: (coaching, user) => {
        let progName = coaching?.program || null;
        let progOptionList = [
          { key: "long_covid", value: "Long Covid" },
          { key: "auto_immune", value: "Auto Immune" },
        ];
        if (progName === "long_covid") {
          progName = "Long Covid";
        }
        if (progName === "auto_immune") {
          progName = "Auto Immune";
        }
        return (
          <div style={{ flex: 1, flexDirection: "row" }}>
            <Select
              // mode="multiple"
              allowClear={false}
              style={{ width: "100%" }}
              placeholder="Select progm"
              value={progName}
              onChange={(value) =>
                handleEduProgChange(user.firebase_id, value, user?.coaching)
              }
            >
              {progOptionList.map((prog) => (
                <Option value={prog.key}>{prog.value}</Option>
              ))}
            </Select>
          </div>
        );
      },
    },
    {
      title: "Assign Communities",
      dataIndex: "communities",
      key: "communities",
      width: 200,
      render: (communities, user) => {
        let communityOptions = [];
        let communitiesPresent = communities?.filter(
          (comm) => comm.users[user.firebase_id] === true
        );
        let commIds = communitiesPresent.map((comm) => comm._id);

        communities.forEach((comm) => {
          communityOptions.push(<Option key={comm._id}>{comm.title}</Option>);
        });

        return (
          <Select
            mode="multiple"
            allowClear={false}
            style={{ width: "100%" }}
            placeholder="Select community"
            value={commIds}
            onSelect={(value) => addCommunity(value, user.firebase_id)}
            onDeselect={(value) => delCommunity(value, user.firebase_id)}
          >
            {communityOptions}
          </Select>
        );
      },
    },
    {
      title: "Meal Plan",
      dataIndex: "suggestic_program",
      key: "suggestic_program",
      width: 200,
      render: (suggestic_program) => {
        return (
          suggestic_program &&
          suggestic_program.data && <p>{suggestic_program.data.name}</p>
        );
      },
    },
  ];
};
